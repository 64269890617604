import React from 'react';
import { Handle, Position, NodeProps } from '@xyflow/react';
import { Card, CardContent, Typography } from '@mui/material';

interface CustomNodeProps extends NodeProps {
  data: {
    label: string;
    step: string;
    description: string;
  };
}

const CustomNode: React.FC<CustomNodeProps> = ({ data }) => {
  return (
    <Card sx={{ border: '2px solid #ccc' }}>
      <CardContent>
        <Typography variant="h6" component="div">
          {data.label}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          Step: {data.step}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {data.description}
        </Typography>
      </CardContent>
      <Handle type="target" position={Position.Top} />
      <Handle type="source" position={Position.Bottom} />
    </Card>
  );
};

export default CustomNode;
