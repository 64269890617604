import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { alpha } from '@mui/material/styles';
import type { } from '@mui/x-charts/themeAugmentation';
import type { } from '@mui/x-data-grid/themeAugmentation';
import type { } from '@mui/x-date-pickers/themeAugmentation';
import type { } from '@mui/x-tree-view/themeAugmentation';
import * as React from 'react';
import AppNavbar from '../../components/AppNavbar';
import Header from '../../components/Header';
import SideMenu from '../../components/SideMenu';

export default function MainLayout({children}: React.PropsWithChildren) {
  return (
    <Box>
      <Box 
      sx={{ display: 'flex',height: '100vh',backgroundColor: 'background.paper',p:2 }}
      >
        <SideMenu />
        <AppNavbar />
        {/* Main content */}
        <Box
          component="main"
          sx={(theme) => ({
            flexGrow: 1,
            backgroundColor: alpha(theme.palette.background.default, .5),
            overflow: 'auto',
          })}
          px={4}
        >
          <Stack
            spacing={2}
            sx={{
              // alignItems: 'center',
              // mx: 3,
              // pb: 5,
              // mt: { xs: 8, md: 0 },
              mt: 0
            }}
          >
            {/* <Header /> */}
            {children}
          </Stack>
        </Box>
      </Box>
      </Box>
  );
}
