import React, { useState } from "react";
import MainLayout from "../../layouts/main/main_layout";
import { Box, Divider, Tab, Tabs, Typography } from "@mui/material";
import Roles from "./roles";
import Users from "./users";

export default function IAM() {
  const [currentTab, setCurrentTab] = useState(0);
  return (
    <MainLayout>
      <Box sx={{ width: "100%" }}>
        {/* cards */}
        <Box display={"flex"} justifyContent={"space-between"} mt={2} mb={2}>
          <Typography component="h2" variant="h6">
            Users & Roles
          </Typography>
          </Box>
      <Tabs value={currentTab} onChange={(_, tab) => setCurrentTab(tab)}>
        <Tab label="Users" value={0}></Tab>
        <Tab label="Roles" value={1}></Tab>
      </Tabs>
      <Divider sx={{ margin: "0px !important" }} />
      <Box mt={2}>
      {currentTab === 0 && <Users />}
      {currentTab === 1 && <Roles />}
      </Box>
      </Box>
    </MainLayout>
  );
}
