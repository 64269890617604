import {
  Alert,
  Badge,
  Box,
  Divider,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import {
  DataGrid,
  GridColDef,
  GridRowHeightParams,
  GridRowId,
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import { useState } from "react";
import { validate } from "jsonschema";

import Editor from "@monaco-editor/react";
import schema from "./schema";

export default function Execution({
  logs,
  json,
  content,
}: {
  logs: any[];
  json: any;
  content: string;
}) {
  const [activeTab, setActiveTab] = useState<number>(1);
  const [expandedRows, setExpandedRows] = useState<{
    [key: GridRowId]: boolean;
  }>({});

  const getRowHeight = (params: GridRowHeightParams) =>
    expandedRows[params.id] ? 280 : 40;

  const columns: GridColDef[] = [
    {
      field: "timestamp",
      headerName: "Timestamp",
      renderCell: (row: any) => {
        return (
          <Typography variant="caption">
            {dayjs(row.row.timestamp).format("HH:mm:ss")}
          </Typography>
        );
      },
    },
    {
      field: "severity",
      headerName: "Severity",
      renderCell: (row: any) => {
        return (
          <Typography variant="caption">
            {row.row?.log?.severity || "info"}
          </Typography>
        );
      },
    },
    {
      field: "summary",
      headerName: "Summary",
      flex: 1,

      renderCell: (row: any) => {
        console.log("row", row);
        return (
          <Box>
            {expandedRows[row.id] ? (
              <Box onClick={(e) => e.stopPropagation()}>
                <Editor
                  language="json"
                  height={280}
                  value={JSON.stringify(row.row?.log || {}, undefined, 2)}
                  options={{
                    readOnly: true,
                    minimap: { enabled: false },
                    fontSize: 12,
                    lineNumbersMinChars: 2,
                    padding: { top: 5, bottom: 5 },
                    scrollbar: {
                      vertical: "hidden",
                      horizontal: "hidden",
                    },
                    overviewRulerLanes: 0,
                  }}
                />
              </Box>
            ) : (
              <Typography variant="caption">
                {row.row?.log?.msg || JSON.stringify(row.row?.log || {})}
              </Typography>
            )}
          </Box>
        );
      },
    },
  ];

  const valid = validate(json, schema, {});
  console.log("valid", valid);

  function getJsonErrors() {
    try {
      JSON.parse(content);
      return null;
    } catch (e: any) {
      return e?.message || "JSON Syntax error";
    }
  }

  let error = getJsonErrors();

  return (
    <Box
      pt={1}
      sx={{ borderTop: `1px solid ${grey[400]}` }}
      display="flex"
      flexDirection="column"
      justifyContent="stretch"
      height="100%"
    >
      <Tabs
        value={activeTab}
        sx={{ color: "white" }}
        onChange={(e, tab) => setActiveTab(tab)}
      >
        <Tab
          label="Problems"
          value={1}
          iconPosition="end"
          icon={<Badge color="primary">{valid.errors.length}</Badge>}
        ></Tab>
        <Tab label="Workflow Executor Logs" value={2}></Tab>
      </Tabs>
      <Divider sx={{ borderTop: `1px solid ${grey[300]}` }} />
      <Box flex={1} height={"100%"} overflow="scroll">
        {activeTab === 1 && (
          <Box>
            {error ? <Alert severity="error">{error}</Alert> : null}
            {valid.errors?.map((err) => (
              <Alert severity="error">
                {err.property.replace(/^instance\./, "workflow.")}:{" "}
                {err.message}
              </Alert>
            ))}
          </Box>
        )}
        {activeTab === 2 && (
          <DataGrid
            density="compact"
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableRowSelectionOnClick
            rows={logs}
            columns={columns}
            getRowId={(row) => row.id}
            getRowHeight={getRowHeight}
            onRowClick={(params) => {
              setExpandedRows((prev) => ({
                ...prev,
                [params.id]: !prev[params.id],
              }));
            }}
            sx={{
              "& .MuiDataGrid-row": {
                borderBottom: "1px solid #ddd", // Adds a divider between rows
              },
            }}
            disableColumnSorting={true}
            disableColumnMenu={true}
          />
        )}
      </Box>
    </Box>
  );
}
