import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MuiCard from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { SitemarkIcon } from "../sign-in/custom-icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import useSession from "../../provider/session/session_provider";

const Card = styled(MuiCard)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: "auto",
  [theme.breakpoints.up("sm")]: { maxWidth: "450px" },
  boxShadow:
    "hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px",
  ...theme.applyStyles("dark", {
    boxShadow:
      "hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px",
  }),
}));

const ResetContainer = styled(Stack)(({ theme }) => ({
  height: "calc((1 - var(--template-frame-height, 0)) * 100dvh)",
  minHeight: "100%",
  padding: theme.spacing(2),
  [theme.breakpoints.up("sm")]: { padding: theme.spacing(4) },
  "&::before": {
    content: '""',
    display: "block",
    position: "absolute",
    zIndex: -1,
    inset: 0,
    backgroundImage:
      "radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))",
    backgroundRepeat: "no-repeat",
    ...theme.applyStyles("dark", {
      backgroundImage:
        "radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))",
    }),
  },
}));

export default function ResetPassword(props: { disableCustomTheme?: boolean }) {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
 const { resetPassword } = useSession(); 
  const [password, setPassword] = React.useState("");
  const [confirmedPassword, setConfirmedPassword] = React.useState("");
  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState("");
  const [confirmedPasswordError, setConfirmedPasswordError] = React.useState(false);
  const [confirmedPasswordErrorMessage, setConfirmedPasswordErrorMessage] = React.useState("");
  const [isButtonEnabled, setIsButtonEnabled] = React.useState(false);
   let navigate = useNavigate();

  React.useEffect(() => {
    validateInputs();
  }, [password, confirmedPassword]);

  const validateInputs = () => {
    let isValid = true;

    if (password.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage("Password must be at least 6 characters long.");
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage("");
    }

    if (confirmedPassword.length < 6) {
      setConfirmedPasswordError(true);
      setConfirmedPasswordErrorMessage("Password must be at least 6 characters long.");
      isValid = false;
    } else {
      setConfirmedPasswordError(false);
      setConfirmedPasswordErrorMessage("");
    }

    if (password && confirmedPassword && password !== confirmedPassword) {
      setConfirmedPasswordError(true);
      setConfirmedPasswordErrorMessage("Passwords do not match.");
      isValid = false;
    }

    setIsButtonEnabled(isValid);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isButtonEnabled || !token) return;
    setIsButtonEnabled(false);
    const success = await resetPassword(token, password);

    if (success) {
      setPassword("");
      setConfirmedPassword("");
      navigate("/");
    }
  
    setIsButtonEnabled(true); 
    
  };

  return (
    <ResetContainer direction="column" justifyContent="space-between">
      <Card variant="outlined">
        <SitemarkIcon />
        <Typography
          component="h1"
          variant="h4"
          sx={{ width: "100%", fontSize: "clamp(2rem, 10vw, 2.15rem)" }}
        >
          Reset Password
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 2 }}
        >
          <FormControl>
            <FormLabel htmlFor="password">Password</FormLabel>
            <TextField
              error={passwordError}
              helperText={passwordErrorMessage}
              name="password"
              placeholder="••••••"
              type="password"
              id="password"
              autoComplete="new-password"
              required
              fullWidth
              variant="outlined"
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="confirmed-password">Confirmed Password</FormLabel>
            <TextField
              error={confirmedPasswordError}
              helperText={confirmedPasswordErrorMessage}
              name="confirmed-password"
              placeholder="••••••"
              type="password"
              id="confirmed-password"
              autoComplete="new-password"
              required
              fullWidth
              variant="outlined"
              onChange={(e) => setConfirmedPassword(e.target.value)}
            />
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            disabled={!isButtonEnabled}
          >
            Update
          </Button>
        </Box>
      </Card>
    </ResetContainer>
  );
}
