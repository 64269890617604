export const baseURL = "https://api.34.8.215.20.nip.io/";

export const chatbotIntegrationUrl = "https://static.stgairasia.com/chatbot2/dev/chatbot.min.js";

export const ssoClientId =
  "653724005900-hpptgetqmmgb7m2ufcqnubt3eed7re7o.apps.googleusercontent.com";

export type Feature = "configurations" | "conversations" | "users" | "alerts";

export const features: Feature[] = [
  "configurations",
  "conversations",
  "users",
  "alerts",
];

export type Access = "read" | "write" | "update" | "delete";

export const permissions: Access[] = ["read", "write", "update", "delete"];
