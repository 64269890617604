import { BugReport, Login, Save, Stream } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { chatbotIntegrationUrl } from "../../config";
import httpClient from "../../utils/http";

export default function TestWorkflow({
  workflowId,
  setLogs,
  save,
  lastSaved,
  saving,
  publish,
}: {
  workflowId: string;
  setLogs: any;
  save: () => void;
  lastSaved: null | Date;
  saving: boolean;
  publish: any;
}) {
  const [isLoginDialogOpen, setIsLoginDialogOpen] = useState<boolean>(false);
  const [authToken, setAuthToken] = useState();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginType, setLoginType] = useState<"user" | "agent">("user");
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    (window as any).logger = {};
    (window as any).logger.log = function (log: any) {
      setLogs((prev: any[]) => {
        return [
          {
            id: log.data.id || new Date().valueOf(),
            timestamp: new Date(log.data.time),
            log: log.data,
          },
          ...prev,
        ];
      });
    };
    let script = document.createElement("script");
    script.src = `${chatbotIntegrationUrl}?tag=${new Date().valueOf()}`;
    document.getElementById("root")?.appendChild(script);
    return () => {
      script.remove();
    };
  }, []);

  function login() {
    httpClient
      .get("v1/user/sandbox/login", {
        params:
          username && password
            ? {
                username,
                password,
              }
            : null,
      })
      .then(({ data }) => {
        console.log(data.data.accessToken);
        if (data.data.accessToken) {
          setAuthToken(data.data.accessToken);
          (window as any).chatbot.authorize(data.data.accessToken);
          setIsLoginDialogOpen(false);
          (window as any).chatbot.setConfig({
            workflowId: workflowId,
            position: { bottom: -100, right: -100 },
          });
          (window as any).chatbot.endSession();
          (window as any).chatbot.open();
        } else {
          enqueueSnackbar("Invalid credentials");
        }
      })
      .catch((e) => {});
  }

  function loginAsAgent() {
    (window as any).chatbot.authorizeLemon({
      agentID: "OTE5OTIxMA--",
      orgCode: "28282828",
      customerNumber: "9340040714",
      emailAddress: "testagent4623@gmail.com",
      firstName: "cl",
      lastName: "hue",
      lemonAuthToken: "",
      lemonAuthRefreshToken: "",
      wallet: {},
    });
    setIsLoginDialogOpen(false);
    (window as any).chatbot.setConfig({
      workflowId: workflowId,
      position: { bottom: -100, right: -100 },
    });
    (window as any).chatbot.endSession();
    (window as any).chatbot.open();
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Button
        variant="text"
        color={saving ? "inherit" : "success"}
        sx={{
          // color: 'white',
          ml: 2,
        }}
        onClick={save}
        startIcon={<Save />}
      >
        {saving ? "Saving" : "Save"}
      </Button>
      <Box p={0.5} />
      <Button
        startIcon={<BugReport />}
        variant="text"
        color="info"
        onClick={() => setIsLoginDialogOpen(true)}
      >
        Test workflow
      </Button>
      <Box p={0.5} />
      {publish ? (
        <Button
          startIcon={<Stream />}
          variant="text"
          color="success"
          onClick={publish}
        >
          Publish
        </Button>
      ) : null}

      <Dialog
        onClose={() => setIsLoginDialogOpen(false)}
        open={isLoginDialogOpen}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Login</DialogTitle>
        <Box display="flex" flexDirection="column" py={1} px={2} pb={2}>
          <ToggleButtonGroup
            exclusive
            onChange={(e, v) => setLoginType(v)}
            aria-label="Platform"
            value={loginType}
            fullWidth
          >
            <ToggleButton value="user">User</ToggleButton>
            <ToggleButton value="agent">Agent</ToggleButton>
          </ToggleButtonGroup>
          <Box p={1} />
          {loginType === "user" ? (
            <Box>
              <TextField
                label="Email"
                name="email"
                value={username}
                onChange={({ target: { value } }) => setUsername(value)}
                fullWidth
              />
              <Box p={1} />
              <TextField
                label="Password"
                name="password"
                value={password}
                onChange={({ target: { value } }) => setPassword(value)}
                fullWidth
              />
              <Box
                py={2}
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
                <Button variant="contained" onClick={login}>
                  Login
                </Button>
              </Box>
            </Box>
          ) : null}

          {loginType === "agent" ? (
            <Box>
              <List>
                <ListItem>
                  <ListItemText
                    primary="cl hue • 9340040714"
                    secondary="testagent4623@gmail.com • OTE5OTIxMA--"
                  />
                  <ListItemIcon>
                    <IconButton onClick={loginAsAgent}>
                      <Login />
                    </IconButton>
                  </ListItemIcon>
                </ListItem>
              </List>
            </Box>
          ) : null}
        </Box>

        <Divider />

        <Box
          py={2}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            variant="outlined"
            onClick={() => {
              setIsLoginDialogOpen(false);
              (window as any).chatbot.setConfig({
                workflowId: workflowId,
                position: { bottom: -100, right: -100 },
              });
              (window as any).chatbot.endSession();
              (window as any).chatbot.open();
            }}
          >
            Continue as guest
          </Button>
        </Box>
      </Dialog>
    </Box>
  );
}
