import CssBaseline from "@mui/material/CssBaseline";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import router from "./router";
import AppTheme from "./theme/AppTheme";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { SnackbarProvider } from "notistack";
import { ssoClientId } from "./config";
import {
  chartsCustomizations,
  dataGridCustomizations,
  datePickersCustomizations,
  treeViewCustomizations,
} from "./theme/customizations";

const xThemeComponents = {
  ...chartsCustomizations,
  ...dataGridCustomizations,
  ...datePickersCustomizations,
  ...treeViewCustomizations,
};

function App(props: { disableCustomTheme?: boolean }) {
  return (
    <AppTheme disableCustomTheme={false} {...props} themeComponents={xThemeComponents}>
      <CssBaseline enableColorScheme />
      <SnackbarProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <GoogleOAuthProvider clientId={ssoClientId}>
          <RouterProvider router={router} />
          </GoogleOAuthProvider>
        </LocalizationProvider>
      </SnackbarProvider>
      <ToastContainer />
    </AppTheme>
  );
}

export default App;
