import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  FormControl,
  FormControlLabel,
  List,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { features, permissions } from "../../config";
import httpClient from "../../utils/http";
import useFeedback from "../../provider/feedback/feedback_provider";
import { useQuery } from "@tanstack/react-query";
import useSession from "../../provider/session/session_provider";

export interface Role {
  name: string;
  isSuperAdmin: boolean;
  permissions: Permission[];
}

export interface Permission {
  feature: string;
  read: boolean;
  write: boolean;
  update: boolean;
  delete: boolean;
}

export default function Roles() {
  const { hasPermission } = useSession();
  const {data,refetch} = useQuery({
    queryKey: ["roles"],
    queryFn: async () => {
      return (await httpClient.get("/v1/admin/roles")).data?.data;
    }
  });
  const [isRolesAddDialogOpen, setIsRolesAddDialogOpen] =
    useState<boolean>(false);
  const { showSnackbar } = useFeedback();
  const { control, handleSubmit, reset, watch,setValue, } = useForm<Role>({
    defaultValues: {
      name: "",
      isSuperAdmin: false,
      permissions: features.map((feature) => ({
        feature,
        read: false,
        write: false,
        update: false,
        delete: false,
      })),
    },
  });
  const isSuperAdmin = watch("isSuperAdmin", false);

  useEffect(() => {
    if(isSuperAdmin){
      setValue("permissions",features.map((feature) => ({
        feature,
        read: true,
        write: true,
        update: true,
        delete: true,
      })));
    }
  },[isSuperAdmin,setValue]);

  async function addRole(role: Role) {
    httpClient
      .post("v1/admin/roles", role)
      .then(({ data, status }) => {
        showSnackbar(`Role added successfully.`, "success");
        setIsRolesAddDialogOpen(false);
        refetch();
        reset();
      })
      .catch((e) => {
        showSnackbar(`Error adding role: ${e?.response?.data?.message || e.message}`, "error");
      });
  }


  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "isSuperAdmin",
      headerName: "Is Super Admin",
      flex: 1,
      renderCell: (params) => {
        return params.row[params.field] ? 'Yes': 'No'
      }
    },
    {
      field: "permissions",
      headerName: "Access",
      flex: 4,
      renderCell: (params) => {
        return (<span>
            {params.row["permissions"]
              .map(
                (x: any) => <span><b style={{textTransform: 'capitalize'}}>{x["feature"]}</b>{`: (${[
                    x["read"] ? "Read" : null,
                    x["write"] ? "Write" : null,
                    x["update"] ? "Update" : null,
                    x["delete"] ? "Delete" : null,
                  ]
                    .filter((x) => x)
                    .join(", ")})`}&nbsp;&nbsp;</span>
              )}
              </span>
        );
      },
    },
  ];
  return (
    <Box>
      <Box display="flex" justifyContent="flex-end" mb={2}>
      {hasPermission("users", "write") ? (
        <Button
          variant="contained"
          onClick={() => setIsRolesAddDialogOpen(true)}
        >
          Add role
        </Button>) : null}

      </Box>
      <DataGrid
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        disableRowSelectionOnClick
        rows={data}
        columns={columns}
      />

      <Dialog
        onClose={() => setIsRolesAddDialogOpen(false)}
        open={isRolesAddDialogOpen}
        maxWidth={"xs"}
        fullWidth
      >
        <DialogTitle>Add Role</DialogTitle>
        <Box px={2} pb={2}>
          <form onSubmit={handleSubmit((fields) => addRole(fields))}>
            <Controller
              control={control}
              name="name"
              rules={{required: true}}
              render={({
                field,
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => {
                return (
                  <TextField
                    fullWidth
                    label="Name"
                    placeholder="Name"
                    {...field}
                  />
                );
              }}
            />

            <Box mt={2} mx={2}>
              <Controller
                control={control}
                name="isSuperAdmin"
                render={({
                  field: { onChange }
                }) => {
                  return (
                    <FormControlLabel
                      label="Is a superadmin?"
                      control={
                        <Checkbox
                          onChange={(e, checked) => onChange(checked)}
                        />
                      }
                    />
                  );
                }}
              />
            </Box>

            {!isSuperAdmin && (
              <Controller
                control={control}
                name="permissions"
                render={({ field: { onChange, value } }) => {
                  return (
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Feature</TableCell>
                          {permissions.map((permission) => (
                            <TableCell sx={{ textTransform: "capitalize" }}>
                              {permission}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {value.map((feature, index) => (
                          <TableRow>
                            <TableCell sx={{ textTransform: "capitalize" }}>
                              {feature.feature}
                            </TableCell>
                            {permissions.map((permission) => (
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={feature[permission] || false}
                                  onChange={(_, checked) => {
                                    value[index][permission] = checked;
                                    if (checked) {
                                      value[index]["read"] = true;
                                    } else if (
                                      permission === "read" &&
                                      !checked
                                    ) {
                                      value[index]["write"] = false;
                                      value[index]["update"] = false;
                                      value[index]["delete"] = false;
                                    }
                                    onChange([...value]);
                                  }}
                                />
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  );
                }}
              />
            )}

            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Button
                onClick={() => {
                  reset();
                  setIsRolesAddDialogOpen(false);
                }}
                sx={{ mr: 1 }}
              >
                Cancel
              </Button>
              <Button variant="contained" type="submit">
                Add
              </Button>
            </Box>
          </form>
        </Box>
      </Dialog>
    </Box>
  );
}
