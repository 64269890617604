import {
  createBrowserRouter
} from "react-router-dom";
import Configurations from "./views/configurations/configurations";
import Dashboard from "./views/dashboard/dashboard";
import LeftSidebar from "./views/conversation/conversation";
import PrivateRoute from "./components/PrivateRoute";
import NotFound from "./views/notfound/NotFound";
import Alerts from "./views/alerts/alerts";
import Workflows from "./views/workflows/workflows";
import ResetPassword from "./views/reset-password/reset-password";
import SignIn from "./views/sign-in/sign-in";
import IAM from "./views/users_roles/users_roles";
import Designer from "./views/designer/designer";
import Incident from "./views/incidents/incident";

export default createBrowserRouter([
  {
    path: "/",
    element: <SignIn />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/dashboard",
    element: (
      <PrivateRoute element={<Dashboard />} /> 
    )
  },
  {
    path: "/configurations",
    element: (
      <PrivateRoute element={<Configurations />} requiredPermission="configurations" requiredAccess="read" />  
    )
  },
  {
    path: "/conversation/all",
    element: (
      <PrivateRoute element={<LeftSidebar />} requiredPermission="conversations" requiredAccess="read" />  
    )
  },
  {
    path: "/alerts",
    element: (
      <PrivateRoute element={<Alerts />} requiredPermission="alerts" requiredAccess="read" />  
    )
  },
  {
    path: "/incident",
    element: (
      <PrivateRoute element={<Incident />} />  
    )
  },
  {
    path: "/workflows",
    element: (
      <PrivateRoute element={<Workflows />} />  
    )
  },
  {
    path: "/users-and-roles",
    element: (
      <PrivateRoute element={<IAM />} requiredPermission="users" requiredAccess="read" />  
    )
  },
  {
    path: "/designer/:workflowId",
    element: (
      <Designer />
    )
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);
