import { useQuery } from "@tanstack/react-query";
import {
  addEdge,
  Background,
  BackgroundVariant,
  Controls,
  MiniMap,
  ReactFlow,
  useEdgesState,
  useNodesState,
} from "@xyflow/react";
import { useCallback, useEffect, useState } from "react";
import CustomNode from "./../../components/CustomNode";

import { Air } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import "@xyflow/react/dist/style.css";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useParams } from "react-router-dom";
import httpClient from "../../utils/http";
import JSONEditor from "./editor";
import Execution from "./execution";
import TestWorkflow from "./test_workflow";

export interface Workflow {
  id: number;
  workflowId: string;
  testJson: any;
  liveJson: any;
  isActive: boolean;
}

const getPosition = (index: number) => {
  let x = 150;
  const nodeHeight = 150;
  let y = index * nodeHeight + 50;

  return { x, y };
};

export default function App() {
  const [content, setContent] = useState<any>("");
  const [json, setJson] = useState<any>({});
  const [logs, setLogs] = useState<any[]>([]);
  const [lastSaved, setLastSaved] = useState<Date | null>(null);
  const [saving, setSaving] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [nodes, setNodes, onNodesChange] = useNodesState<any>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<any>([
    { style: { strokeWidth: 3, stroke: "black" } },
  ]);
  const { workflowId } = useParams();
  const { data: workflow } = useQuery<Workflow>({
    queryKey: ["workflow", workflowId],
    queryFn: async () => {
      let { data } = await httpClient.get(`/v1/admin/workflow/${workflowId}`);
      return data?.data;
    },
    retry: Infinity,
    staleTime: Infinity,
  });
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (workflow && Object.keys(json).length === 0) {
      setContent(JSON.stringify(workflow.testJson, undefined, 2));
      setJson(workflow.testJson);
    }
  }, [json, workflow]);

  const nodeTypes = {
    custom: CustomNode,
  };

  useEffect(() => {
    const initialNodes =
      json?.steps
        ?.filter((step: any) => step.stepId)
        .map((step: any, index: number) => {
          let conditionIndex = null;
          if (step.type === "condition" && step.conditions) {
            conditionIndex = index;
          }
          if (step.type !== "condition" && step.type !== "options") {
            return {
              id: step.stepId,
              type: "custom",
              position: getPosition(index),
              data: {
                label: step.name,
                step: step.stepId,
                description: step.description || "",
              },
            };
          }
        })
        .filter(Boolean) || [];
    setNodes(initialNodes as any);

    const initialEdges: any[] = [];

    const stepMap: any = {};
    const stepIndexMap: any = {};
    json?.steps?.forEach((step: any, index: number) => {
      stepMap[step.stepId] = step;
      stepIndexMap[step.stepId] = index;
    });

    const incomingEdges: { [stepId: string]: number } = {};
    json?.steps?.forEach((step: any) => {
      if (step.nextStep) {
        incomingEdges[step.nextStep]++;
      }
      if (step.type === "condition" && step.conditions) {
        step.conditions.forEach((condition: any) => {
          if (condition.nextStep) {
            incomingEdges[condition.nextStep]++;
          }
        });
      }
      if (step.type === "options" && step.options) {
        step.options.forEach((option: any) => {
          if (option.nextStep) {
            incomingEdges[option.nextStep]++;
          }
        });
      }
    });

    json?.steps?.forEach((step: any, index: number) => {
      if (step.stepId) {
        let yOffset = 0;
        if (incomingEdges[step.stepId] > 1) {
          yOffset = -100;
        }
        if (step.type === "condition") {
          const totalNextSteps =
            step.conditions?.length || 0 + (step.nextStep ? 1 : 0);
          step.conditions.forEach((condition: any, conditionIndex: number) => {
            if (condition.nextStep) {
              initialEdges.push({
                id: `e_${step.stepId}_${condition.nextStep}`,
                source: step.stepId,
                target: condition.nextStep,
              });
              const nextStep = stepMap[condition.nextStep];
              if (nextStep) {
                const pos = getPosition(index + 1);
                initialNodes.push({
                  id: condition.nextStep,
                  type: "custom",
                  position: {
                    x: pos.x + conditionIndex * 200,
                    y: pos.y + 300 + yOffset,
                  },
                  data: {
                    label: nextStep.name,
                    step: nextStep.stepId,
                    description: nextStep.description,
                  },
                });
              }
            }
          });
          if (step.nextStep) {
            initialEdges.push({
              id: `e_${step.stepId}_${step.nextStep}`,
              source: step.stepId,
              target: step.nextStep,
            });
            const nextStep = stepMap[step.nextStep];
            if (nextStep) {
              const pos = getPosition(index + 1);
              initialNodes.push({
                id: step.nextStep,
                type: "custom",
                position: { x: pos.x, y: pos.y + 300 + yOffset },
                data: {
                  label: nextStep.name,
                  step: nextStep.stepId,
                  description: nextStep.description,
                },
              });
            }
          }
        } else if (step.type === "options") {
          const totalNextSteps = step.options.length;
          step.options.forEach((option: any, optionIndex: number) => {
            if (option.nextStep) {
              initialEdges.push({
                id: `e_${step.stepId}_${option.nextStep}`,
                source: step.stepId,
                target: option.nextStep,
              });
              const nextStep = stepMap[option.nextStep];
              if (nextStep) {
                const pos = getPosition(index + 1);
                initialNodes.push({
                  id: option.nextStep,
                  type: "custom",
                  position: {
                    x: pos.x + optionIndex * 200,
                    y: pos.y + 300 + yOffset,
                  },
                  data: {
                    label: nextStep.name,
                    step: nextStep.stepId,
                    description: nextStep.description,
                  },
                });
              }
            }
          });
        } else if (step.nextStep) {
          initialEdges.push({
            id: `e_${step.stepId}_${step.nextStep}`,
            source: step.stepId,
            target: step.nextStep,
          });
          const nextStep = stepMap[step.nextStep];
          if (nextStep) {
            const pos = getPosition(index + 1);
            initialNodes.push({
              id: step.nextStep,
              type: "custom",
              position: { x: pos.x, y: pos.y + 300 + yOffset },
              data: {
                label: nextStep.name,
                step: nextStep.stepId,
                description: nextStep.description,
              },
            });
          }
        }
      }
    });
    const uniqueNodes = Array.from(
      new Map(initialNodes.map((item: any) => [item.id, item])).values()
    ) as any;
    setNodes(uniqueNodes);
    console.log(uniqueNodes);

    setEdges(initialEdges);
  }, [json]);

  const onConnect = useCallback(
    (params: any) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );

  useEffect(() => {
    try {
      saveJson();
    } catch {}
  }, [json, workflowId, workflow]);

  function saveJson() {
    if (workflow && Object.keys(json).length > 0) {
      setSaving(true);
      httpClient
        .patch(`/v1/admin/workflow/${workflowId}`, json)
        .then(() => {
          setTimeout(() => {
            setSaving(false);
            setLastSaved(new Date());
          }, 1000);
        })
        .catch(() => {
          setSaving(false);
        });
    }
  }

  function publish() {
    httpClient
      .post(`v1/admin/workflows/publish/${workflow?.workflowId}`,{})
      .then(({ data }) => {
        enqueueSnackbar("🎉 Great! Workflow is now live.",{variant: 'success'});
      })
      .catch((e) => {
        enqueueSnackbar("⚠️ Uh-oh! An error occurred.",{variant: 'error'});
      });
  }

  return (
    <PanelGroup autoSaveId="example" direction="horizontal">
      <Panel defaultSize={36}>
        <Box sx={{ borderRight: `1px solid ${grey[400]}` }}>
          <PanelGroup
            style={{ flex: 1, height: "100vh" }}
            autoSaveId="example1"
            direction="vertical"
          >
            <Box
              height={64}
              sx={{ borderBottom: `1px solid ${grey[400]}` }}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              px={2}
            >
              <Box display="flex" justifyContent="center" alignItems="center">
                <Box pr={2}>
                  <Air />
                </Box>
                <Box>
                  <Typography variant="h6">{workflowId}</Typography>
                  <Typography variant="caption">
                    Last saved: {dayjs(lastSaved).format("HH:mm:ss")}
                  </Typography>
                </Box>
              </Box>
              {workflowId && (
                <TestWorkflow
                  workflowId={workflowId}
                  saving={saving}
                  setLogs={setLogs}
                  save={saveJson}
                  lastSaved={lastSaved}
                  publish={workflow?.isActive ? publish : null}
                />
              )}
            </Box>
            <Panel defaultSize={80}>
              {workflowId && (
                <JSONEditor
                  workflowId={workflowId}
                  value={content}
                  onChange={(json: string) => {
                    setContent(json);
                    try {
                      setJson(JSON.parse(json));
                    } catch {}
                  }}
                />
              )}
            </Panel>
            <PanelResizeHandle />
            <Panel>
              <Execution logs={logs} json={json} content={content} />
            </Panel>
          </PanelGroup>
        </Box>
      </Panel>
      <PanelResizeHandle />
      <Panel>
        <Box flex={1} sx={{ width: "100%", height: "100vh" }}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            nodeTypes={nodeTypes}
          >
            <Controls />
            <MiniMap />
            <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
          </ReactFlow>
        </Box>
      </Panel>
    </PanelGroup>
  );
}
