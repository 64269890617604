// import { ReactNode, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import useSession from "../provider/session/session_provider";
// interface PrivateRouteProps {
//   element: ReactNode;
// }

// const PrivateRoute = ({ element }: PrivateRouteProps) => {
//   const navigate = useNavigate();
//   const { authToken } = useSession();

//   useEffect(() => {
//     if (!authToken) {
//       navigate("/");
//     }
//   }, [authToken, navigate]);

// return authToken ? <>{element}</> : null;
// };

// export default PrivateRoute;

import { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Feature, Access } from "../config";
import useSession from "../provider/session/session_provider";

interface PrivateRouteProps {
  element: ReactNode;
  requiredPermission?: Feature;
  requiredAccess?: Access;
}

const PrivateRoute = ({
  element,
  requiredPermission,
  requiredAccess = "read",
}: PrivateRouteProps) => {
  const navigate = useNavigate();
  const { authToken, hasPermission } = useSession();

  useEffect(() => {
    if (!authToken) {
      navigate("/");
    }

    if (
      requiredPermission &&
      !hasPermission(requiredPermission, requiredAccess)
    ) {
      navigate("/");
    }
  }, [authToken, navigate, requiredPermission, requiredAccess, hasPermission]);

  if (!authToken) {
    return null;
  }

  if (
    requiredPermission &&
    !hasPermission(requiredPermission, requiredAccess)
  ) {
    return null;
  }

  return <>{element}</>;
};

export default PrivateRoute;
