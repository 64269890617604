import { Edit, Upload } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Grid2,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import type {} from "@mui/x-charts/themeAugmentation";
import {
  DataGrid,
  GridColDef,
  GridRowsProp,
  GridToolbar,
} from "@mui/x-data-grid";
import type {} from "@mui/x-data-grid/themeAugmentation";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import type {} from "@mui/x-tree-view/themeAugmentation";
import { createRef, useState } from "react";
import MainLayout from "../../layouts/main/main_layout";
import { MuiColorInput } from "mui-color-input";
import AskBoSvg from "../../assets/svgs/askbo-chat.svg.svg";
import { useQuery } from "@tanstack/react-query";
import httpClient from "../../utils/http";
import { queryClient } from "../../provider/query/query_provider";
import useFeedback from "../../provider/feedback/feedback_provider";
import useSession from "../../provider/session/session_provider";

// const rows: GridRowsProp<{
//   id: Number;
//   name: String;
//   description: String;
//   category: String;
//   type: String;
//   defaultValue: any;
//   value: any;
//   options: String;
//   createdAt: Number;
//   updatedAt: Number;
// }> = [
//   {
//     id: 1,
//     name: "chatbotName",
//     description: "To configure the name of the chatbot",
//     category: "CMS",
//     type: "string",
//     defaultValue: null,
//     value: "AskBo",
//     options: "",
//     createdAt: 1433373737377,
//     updatedAt: 1433373737377,
//   },
//   {
//     id: 2,
//     name: "primaryColor",
//     description: "To change the primary color of the chatbot",
//     category: "CMS",
//     type: "color",
//     defaultValue: null,
//     value: "#0F4B54",
//     options: "#ff6666, #006bd6",
//     createdAt: 1433373737377,
//     updatedAt: 1433373737377,
//   },
//   {
//     id: 3,
//     name: "secondaryColor",
//     description: "To change the secondary color of the chatbot",
//     category: "CMS",
//     type: "color",
//     defaultValue: null,
//     value: "#0CE865",
//     options: "#ff6666, #006bd6",
//     createdAt: 1433373737377,
//     updatedAt: 1433373737377,
//   },
//   {
//     id: 4,
//     name: "chatbotLogo",
//     description: "To change the logo of the chatbot",
//     category: "Chatbot",
//     type: "image",
//     defaultValue: null,
//     value: "AskBo.svg",
//     options: "",
//     createdAt: 1433373737377,
//     updatedAt: 1433373737377,
//   },
// ];

export default function Configurations() {
  const [isAddConfigurationDialogOpen, setIsAddConfigurationDialogOpen] =
    useState<boolean>(false);
  const { showSnackbar } = useFeedback();
  const [selectedConfigurationForEdit, setSelectedConfigurationForEdit] =
    useState<{
      id: Number;
      name: String;
      description: String;
      category: String;
      type: String;
      defaultValue: any;
      value: any;
      createdAt: Number;
      updatedAt: Number;
    } | null>(null);
  const fileInputRef = createRef<HTMLInputElement>();
  const {hasPermission} = useSession();

  // function updateConfig(reset: boolean = false) {
  //   let { id, value, defaultValue } = selectedConfigurationForEdit!;
  //   setSelectedConfigurationForEdit(null);
  //   httpClient
  //     .patch("/v1/admin/configs", {
  //       id,
  //       value: reset ? defaultValue : value,
  //     })
  //     .then(({ data }) => {
  //       queryClient.invalidateQueries("configurations");
  //     });
  // }

  function updateConfig(reset: boolean = false) {
    const { id, value, defaultValue, type } = selectedConfigurationForEdit!;

    // Apply hex color validation only for 'color' type
    if (type === "color" && !reset) {
      const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
      if (!hexColorRegex.test(value)) {
        showSnackbar(
          "Invalid color format. Please use a valid HEX color.",
          "error"
        );
        return;
      }
    }

    setSelectedConfigurationForEdit(null); // Reset state after update
    httpClient
      .patch("/v1/admin/configs", {
        id,
        value: reset ? defaultValue : value,
      })
      .then(({ data }) => {
        queryClient.invalidateQueries({queryKey: ["configurations"]});
      });
  }

  const { data: rows } = useQuery<
    {
      id: Number;
      name: String;
      description: String;
      category: String;
      type: String;
      defaultValue: any;
      value: any;
      createdAt: Number;
      updatedAt: Number;
    }[]
  >({
    queryKey: ["configurations"],
    queryFn: async () => {
        return (await httpClient.get("/v1/admin/configs")).data?.data;
      }
  });


  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (cell) => {
        return (
          <Box
            display="flex"
            // justifyContent={"center"}
            alignItems={"center"}
            height={"100%"}
          >
            <Typography fontWeight={500}>{cell.formattedValue}</Typography>
          </Box>
        );
      },
    },
    { field: "description", headerName: "Description", flex: 3 },
    { field: "category", headerName: "Category", flex: 1 },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      type: "singleSelect",
    },
    {
      field: "defaultValue",
      headerName: "Default Value",
      flex: 2,
      renderCell: (params) => {
        switch (params.row.type) {
          case "color":
            return (
              <Box
                display="flex"
                // justifyContent={"center"}
                alignItems={"center"}
                height={"100%"}
              >
                <Box
                  borderRadius={3}
                  height={40}
                  width={40}
                  sx={{ backgroundColor: params.value }}
                ></Box>
              </Box>
            );
          case "image":
            return (
              <Box
                display="flex"
                // justifyContent={"center"}
                alignItems={"center"}
                height={"100%"}
              >
                <Box
                  borderRadius={4}
                  height={40}
                  width={40}
                  overflow={"hidden"}
                >
                  <img src={params.value} height={40} width={40} alt="Logo" />
                </Box>
              </Box>
            );
          default:
            return (
              <Box
                display="flex"
                // justifyContent={"center"}
                alignItems={"center"}
                height={"100%"}
              >
                <Typography fontWeight={500}>
                  {params.formattedValue}
                </Typography>
              </Box>
            );
        }
      },
    },
    {
      field: "value",
      headerName: "Value",
      flex: 2,
      renderCell: (params) => {
        switch (params.row.type) {
          case "color":
            return (
              <Box
                display="flex"
                // justifyContent={"center"}
                alignItems={"center"}
                height={"100%"}
              >
                <Box
                  borderRadius={3}
                  height={40}
                  width={40}
                  sx={{ backgroundColor: params.value }}
                ></Box>
              </Box>
            );
          case "image":
            return (
              <Box
                display="flex"
                // justifyContent={"center"}
                alignItems={"center"}
                height={"100%"}
              >
                <Box
                  borderRadius={4}
                  height={40}
                  width={40}
                  overflow={"hidden"}
                >
                  <img src={params.value} height={40} width={40} alt="Logo" />
                </Box>
              </Box>
            );
          default:
            return (
              <Box
                display="flex"
                // justifyContent={"center"}
                alignItems={"center"}
                height={"100%"}
              >
                <Typography fontWeight={500}>
                  {params.formattedValue}
                </Typography>
              </Box>
            );
        }
      },
    },
    ...(hasPermission("configurations", "update")
    ? [
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (row : any) => {
        return (
          <Box>
            <IconButton
              onClick={() => {
                setSelectedConfigurationForEdit(row.row);
              }}
            >
              <Edit />
            </IconButton>
          </Box>
        );
      },
    },
  ]
  : []),
  ];

  return (
    <MainLayout>
      <Box sx={{ width: "100%" }}>
        {/* cards */}
        <Box display={"flex"} justifyContent={"space-between"} mt={2} mb={2}>
          <Typography component="h2" variant="h6">
            Configurations
          </Typography>
          {/* <Button
            variant="contained"
            onClick={() => setIsAddConfigurationDialogOpen(true)}
          >
            Add Configuration
          </Button> */}
        </Box>

        <DataGrid
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          disableRowSelectionOnClick
          // slots={{ toolbar: GridToolbar }}
          // slotProps={{
          //   toolbar: {
          //     showQuickFilter: true,
          //   },
          // }}
          rows={rows}
          columns={columns}
        />
      </Box>

      <Dialog
        onClose={() => setIsAddConfigurationDialogOpen(false)}
        open={isAddConfigurationDialogOpen}
        fullWidth
      >
        <DialogTitle>Add Configuration</DialogTitle>
        <DialogContent>
          <Grid2 container spacing={2} mt={2}>
            <Grid2 size={{ md: 12 }}>
              <FormControl fullWidth>
                <FormLabel htmlFor="Name">Name</FormLabel>
                <TextField
                  name="Name"
                  placeholder="Name of the configuration"
                  type="text"
                  id="Name"
                  autoFocus
                  required
                  fullWidth
                  variant="outlined"
                />
              </FormControl>
            </Grid2>
            <Grid2 size={{ md: 12 }}>
              <FormControl fullWidth>
                <FormLabel htmlFor="description">Description</FormLabel>
                <TextField
                  name="description"
                  placeholder="Description"
                  type="text"
                  id="description"
                  required
                  fullWidth
                  variant="outlined"
                />
              </FormControl>
            </Grid2>
            <Grid2 size={{ md: 12 }}>
              <FormControl fullWidth>
                <FormLabel htmlFor="category">Category</FormLabel>
                <TextField
                  name="category"
                  placeholder="Category"
                  type="text"
                  id="category"
                  required
                  fullWidth
                  variant="outlined"
                />
              </FormControl>
            </Grid2>
            <Grid2 size={{ md: 12 }}>
              <FormControl fullWidth>
                <FormLabel htmlFor="type">Type</FormLabel>
                <TextField
                  name="type"
                  placeholder="type"
                  type="text"
                  id="type"
                  required
                  fullWidth
                  variant="outlined"
                  select
                >
                  {["string", "number", "datetime", "color", "duration"].map(
                    (item) => (
                      <MenuItem>{item}</MenuItem>
                    )
                  )}
                </TextField>
              </FormControl>
            </Grid2>
            <Grid2 size={{ md: 12 }}>
              <FormControl fullWidth>
                <FormLabel htmlFor="defaultValue">Default Value</FormLabel>
                <TextField
                  name="defaultValue"
                  placeholder="Default Value"
                  type="text"
                  id="defaultValue"
                  required
                  fullWidth
                  variant="outlined"
                />
              </FormControl>
            </Grid2>
            <Grid2 size={{ md: 12 }}>
              <FormControl fullWidth>
                <FormLabel htmlFor="value">Value</FormLabel>
                <TextField
                  name="value"
                  placeholder="Value"
                  type="text"
                  id="value"
                  required
                  fullWidth
                  variant="outlined"
                />
              </FormControl>
            </Grid2>
            <Grid2 size={{ md: 12 }}>
              <FormControl fullWidth>
                <FormLabel htmlFor="value">Options</FormLabel>
                <TextField
                  name="options"
                  placeholder="Enter comma seperated values"
                  type="text"
                  id="options"
                  required
                  fullWidth
                  variant="outlined"
                />
              </FormControl>
            </Grid2>
          </Grid2>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setIsAddConfigurationDialogOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => setIsAddConfigurationDialogOpen(false)}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        onClose={() => setSelectedConfigurationForEdit(null)}
        open={selectedConfigurationForEdit != null}
        fullWidth
      >
        <DialogTitle>
          Update Configuration: {selectedConfigurationForEdit?.name}
        </DialogTitle>
        <DialogContent>
          <Grid2 container spacing={2} mt={2}>
            <Grid2 size={{ md: 12 }}>
              <FormControl fullWidth>
                <FormLabel htmlFor="value">Value</FormLabel>
                {(() => {
                  switch (selectedConfigurationForEdit?.type) {
                    case "color":
                      return (
                        // <MuiColorInput
                        //   format="hex"
                        //   value={selectedConfigurationForEdit?.value}
                        //   onChange={(value) => {
                        //     selectedConfigurationForEdit!.value = value;
                        //     setSelectedConfigurationForEdit({
                        //       ...selectedConfigurationForEdit!,
                        //     });
                        //   }}
                        // />
                        <MuiColorInput
                          format="hex"
                          value={selectedConfigurationForEdit?.value || ""}
                          onChange={(value) => {
                            const updatedValue = value;
                            setSelectedConfigurationForEdit({
                              ...selectedConfigurationForEdit!,
                              value: updatedValue,
                            });
                          }}
                        />
                      );
                    case "image":
                      return (
                        <Box>
                          <Button
                            variant="contained"
                            startIcon={<Upload />}
                            onClick={() => fileInputRef.current?.click()}
                          >
                            File upload
                          </Button>
                          <input
                            style={{ display: "none" }}
                            type="file"
                            // accept="image/png, image/jpeg"
                            accept="image/png, image/jpeg"
                            ref={fileInputRef}
                            onChange={({ target: { files } }) => {
                              // if (files?.length) {
                              //   const MAX_FILE_SIZE = 1 * 1024 * 1024;
                              //   if (files[0].size > MAX_FILE_SIZE) {
                              //     showSnackbar(
                              //       "File size should not exceed 1MB.",
                              //     "error"
                              //     );
                              //     return;
                              //   }
                              //   var reader = new FileReader();
                              //   reader.readAsDataURL(files[0]);

                              //   reader.onload = function (e) {
                              //     selectedConfigurationForEdit!.value =
                              //       reader.result;
                              //     setSelectedConfigurationForEdit({
                              //       ...selectedConfigurationForEdit!,
                              //     });
                              //   };
                              //   reader.onerror = function () {
                              //     console.log("there are some problems");
                              //   };
                              // }
                              if (files?.length) {
                                const MAX_FILE_SIZE = 1 * 1024 * 1024;
                                // const allowedTypes = [
                                //   "image/png",
                                //   "image/jpeg",
                                //   "image/svg+xml",
                                // ];
                                const allowedTypes = [
                                  "image/png", // Only PNG
                                  "image/jpeg", // Only JPEG
                                ];

                                // Check for allowed file type
                                if (!allowedTypes.includes(files[0].type)) {
                                  showSnackbar(
                                    "Invalid file type. Only PNG and JPEG are allowed.",
                                    "error"
                                  );
                                  return;
                                }

                                if (files[0].size > MAX_FILE_SIZE) {
                                  showSnackbar(
                                    "File size should not exceed 1MB.",
                                    "error"
                                  );
                                  return;
                                }

                                const reader = new FileReader();
                                reader.readAsDataURL(files[0]);

                                reader.onload = function (e) {
                                  selectedConfigurationForEdit!.value =
                                    reader.result;
                                  setSelectedConfigurationForEdit({
                                    ...selectedConfigurationForEdit!,
                                  });
                                };

                                reader.onerror = function () {
                                  console.log("There are some problems");
                                };
                              }
                            }}
                          />

                          <Typography variant="body2" mt={2} mb={2}>
                            Preview
                          </Typography>
                          <Box>
                            <img
                              src={selectedConfigurationForEdit?.value}
                              height={120}
                              width={120}
                              alt="logo"
                            />
                          </Box>
                        </Box>
                      );
                    default:
                      return (
                        <TextField
                          name="value"
                          placeholder="Value"
                          type="text"
                          id="value"
                          required
                          fullWidth
                          variant="outlined"
                          value={selectedConfigurationForEdit?.value}
                          onChange={({ target: { value } }) => {
                            selectedConfigurationForEdit!.value = value;
                            setSelectedConfigurationForEdit({
                              ...selectedConfigurationForEdit!,
                            });
                          }}
                        />
                      );
                  }
                })()}
              </FormControl>
            </Grid2>
          </Grid2>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => setSelectedConfigurationForEdit(null)}
          >
            Cancel
          </Button>
          <Button variant="outlined" onClick={() => updateConfig(true)}>
            Reset to default
          </Button>
          <Button variant="contained" onClick={() => updateConfig(false)}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </MainLayout>
  );
}
