import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRenderEditCellParams,
} from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { features, permissions } from "../../config";
import httpClient from "../../utils/http";
import useFeedback from "../../provider/feedback/feedback_provider";
import { Role } from "./roles";
import useSession from "../../provider/session/session_provider";

export interface User {
  name: string;
  department: string;
  email: string;
  password: string | null;
  roleId: number | null;
  isActive: boolean;
}

export default function Users() {
const { hasPermission } = useSession();
  const { data: roles } = useQuery({
    queryKey: ["roles"],
    queryFn: async () => {
      return (await httpClient.get("/v1/admin/roles")).data?.data;
    },
  });
  const [isRolesAddDialogOpen, setIsRolesAddDialogOpen] =
    useState<boolean>(false);
  const { data, refetch } = useQuery({
    queryKey: ["users"],
    queryFn: async () => {
      return (await httpClient.get("/v1/admin/users")).data?.data;
    },
  });

  const { showSnackbar } = useFeedback();
  const { control, handleSubmit, reset } = useForm<User>({
    defaultValues: {
      name: "",
      isActive: false,
      department: "",
      email: "",
      password: "",
      roleId: null,
    },
  });

  function activate(id: number, isActive: boolean, roleId: any) {
    httpClient
      .patch(`v1/admin/users/${id}`, { isActive, roleId })
      .then(({ data, status }) => {
        refetch();
        showSnackbar(`User access updated successfully,`, "success");
        setIsRolesAddDialogOpen(false);
      })
      .catch((e) => {
        showSnackbar(
          `Error updating user access: ${
            e?.response?.data?.message || e.message
          }`,
          "error"
        );
      });
  }

  async function addUser(user: User) {
    httpClient
      .post("v1/admin/users", user)
      .then(({ data, status }) => {
        showSnackbar(`Role added successfully.`, "success");
        setIsRolesAddDialogOpen(false);
        refetch();
      })
      .catch((e) => {
        showSnackbar(
          `Error adding user: ${e?.response?.data?.message || e.message}`,
          "error"
        );
      });
  }

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "department",
      headerName: "Department/Unit",
      flex: 1,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 3,
      editable: hasPermission("users", "update") ? true : false,
      renderEditCell: (params: GridRenderEditCellParams) => (
        <TextField
          select
          fullWidth
          onChange={(e) => {
            activate(params.row["id"], params.row["isActive"], e.target.value);
            params.api.stopCellEditMode({ field: params.field, id: params.id });
          }}
        >
          {roles.map((role: any) => (
            <MenuItem value={role.id}>{role.name}</MenuItem>
          ))}
        </TextField>
      ),
    },

    ...(hasPermission("users", "update")
    ? [
    {
      field: "isActive",
      headerName: "Active",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Switch
            checked={params.row[params.field]}
            onChange={(_, checked) =>
              activate(params.row["id"], checked, params.row["roleId"])
            }
          />
        );
      },
    },
  ]
  : []),
  ];

  if (!roles) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={300}
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Box>
      <Box display="flex" justifyContent="flex-end" mb={2}>
      {hasPermission("users", "write") ? (
        <Button
          variant="contained"
          onClick={() => setIsRolesAddDialogOpen(true)}
          disabled={!roles}
        >
          Add user
        </Button> ) : null}
      </Box>
      <DataGrid
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        disableRowSelectionOnClick
        rows={data}
        columns={columns}
      />

      <Dialog
        onClose={() => setIsRolesAddDialogOpen(false)}
        open={isRolesAddDialogOpen}
        maxWidth={"xs"}
        fullWidth
      >
        <DialogTitle>Add user</DialogTitle>
        <Box px={2} pb={2}>
          <form onSubmit={handleSubmit((fields) => addUser(fields))}>
            <Controller
              control={control}
              name="name"
              rules={{ required: true }}
              render={({
                field,
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => {
                return (
                  <TextField
                    fullWidth
                    label="Name"
                    required
                    placeholder="Name"
                    {...field}
                    sx={{ my: 1 }}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="department"
              rules={{ required: true }}
              render={({
                field,
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => {
                return (
                  <TextField
                    fullWidth
                    label="Department"
                    required
                    placeholder="Department"
                    {...field}
                    sx={{ my: 1 }}
                  />
                );
              }}
            />

            <Controller
              control={control}
              name="email"
              rules={{
                required: true,
                pattern: {
                  value: /^[a-zA-Z0-9._-]+@airasia\.com$/,
                  message: "Invalid email.",
                },
              }}
              render={({
                field,
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => {
                return (
                  <TextField
                    fullWidth
                    type="email"
                    label="Email"
                    placeholder="Email"
                    required
                    {...field}
                    sx={{ my: 1 }}
                    error={!!error}
                    helperText={error?.message}
                  />
                );
              }}
            />

            <Controller
              control={control}
              name="password"
              render={({
                field,
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => {
                return (
                  <TextField
                    fullWidth
                    type="password"
                    label="Password"
                    placeholder="Password"
                    {...field}
                    sx={{ my: 1 }}
                  />
                );
              }}
            />

            <Controller
              control={control}
              name="roleId"
              rules={{ required: "Select a role" }}
              render={({
                field,
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => {
                return (
                  <TextField
                    select
                    fullWidth
                    label="Role"
                    placeholder="Role"
                    {...field}
                    sx={{ my: 1 }}
                    error={!!error}
                    helperText={error?.message}
                  >
                    {roles.map((role: any) => (
                      <MenuItem value={role.id}>{role.name}</MenuItem>
                    ))}
                  </TextField>
                );
              }}
            />

            <Controller
              control={control}
              name="isActive"
              render={({
                field,
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => {
                return (
                  <FormControlLabel
                    label="Is active?"
                    control={
                      <Switch checked={field.value} onChange={field.onChange} />
                    }
                  />
                );
              }}
            />

            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Button
                onClick={() => {
                  reset();
                  setIsRolesAddDialogOpen(false);
                }}
                sx={{ mr: 1 }}
              >
                Cancel
              </Button>
              <Button variant="contained" type="submit">
                Add
              </Button>
            </Box>
          </form>
        </Box>
      </Dialog>
    </Box>
  );
}
