import React from "react";
import { Box, Button, List, ListItem } from "@mui/material";
import PersonListItem from "./PersonListItem";

interface Person {
  id: number;
  name: string;
  date: string;
}

interface PersonListProps {
  data:
    | null
    | {
        sessionId: string;
        userId: string;
        username: string;
        firstName: string;
        lastName: string;
        createdAt: string;
      }[];
  selectedPerson: string | null;
  setSelectedPerson: (id: string) => void;
  loadMore: () => void;
  showLoadMore: boolean;
}

const PersonList: React.FC<PersonListProps> = ({
  data,
  selectedPerson,
  setSelectedPerson,
  loadMore,
  showLoadMore,
}) => {
  return (
    <List
      sx={{
        flex: 1,
        overflowY: "hidden",
        scrollbarWidth: "thin",
        scrollbarGutter: "stable",
        paddingRight: { xs: "12px", sm: "16px" },
        "&:hover": {
          overflowY: "auto",
        },
      }}
    >
      {data?.map((session) => (
        <PersonListItem
          key={session.sessionId}
          sessiondId={session.sessionId}
          name={session.username || "Anonymous"}
          timestamp={session.createdAt}
          selectedPerson={selectedPerson}
          setSelectedPerson={setSelectedPerson}
        />
      ))}

      {showLoadMore && (
        <Box display="flex" justifyContent="center" alignItems="center" p={2}>
          <Button variant="contained" onClick={loadMore}>
            Load more
          </Button>
        </Box>
      )}
    </List>
  );
};

export default PersonList;
