import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MuiCard from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useSession from "../../provider/session/session_provider";
import { SitemarkIcon } from "./custom-icons";
import ForgotPassword from "./forgot-password";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { Access, Feature } from "../../config";

const Card = styled(MuiCard)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: "auto",
  [theme.breakpoints.up("sm")]: {
    maxWidth: "450px",
  },
  boxShadow:
    "hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px",
  ...theme.applyStyles("dark", {
    boxShadow:
      "hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px",
  }),
}));

const SignInContainer = styled(Stack)(({ theme }) => ({
  height: "calc((1 - var(--template-frame-height, 0)) * 100dvh)",
  minHeight: "100%",
  padding: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(4),
  },
  "&::before": {
    content: '""',
    display: "block",
    position: "absolute",
    zIndex: -1,
    inset: 0,
    backgroundImage:
      "radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))",
    backgroundRepeat: "no-repeat",
    ...theme.applyStyles("dark", {
      backgroundImage:
        "radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))",
    }),
  },
}));

export default function SignIn(props: { disableCustomTheme?: boolean }) {
  const theme = useTheme();
  const [emailError, setEmailError] = React.useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState("");
  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState("");
  const [open, setOpen] = React.useState(false);
  let navigate = useNavigate();
  let { login,ssoSignIn, hasPermission, user } = useSession();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const handleClickOpen = () => {
    setEmail("");
    setPassword("");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEmailError(false);
    setEmailErrorMessage("");
    setPasswordError(false);
    setPasswordErrorMessage("");
  };

  const validateInputs = () => {
    // const email = document.getElementById("email") as HTMLInputElement;
    // const password = document.getElementById("password") as HTMLInputElement;

    let isValid = true;

    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setEmailError(true);
      setEmailErrorMessage("Please enter a valid email address.");
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage("");
    }

    if (!password || password.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage("Password must be at least 6 characters long.");
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage("");
    }

    return isValid;
  };


   // Redirect after profile API success
   React.useEffect(() => {
    if (user) {
      const routes: { feature: Feature; access: Access; path: string }[] = [
                { feature: "configurations", access: "read", path: "/configurations" },
                { feature: "conversations", access: "read", path: "/conversation/all" },
                { feature: "alerts", access: "read", path: "/alerts" },
                { feature: "users", access: "read", path: "/users-and-roles" },
              ];
                      
              // Find the first route the user has permission for
              const accessibleRoute = routes.find(({ feature, access }) =>
                hasPermission(feature, access)
              );

      navigate(accessibleRoute ? accessibleRoute.path : "/");
    }
  }, [user, navigate, hasPermission]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // const data = new FormData(event.currentTarget);
    // const email = data.get("email")?.toString() || "";
    // const password = data.get("password")?.toString() || "";

    if (validateInputs()) {
      const isLoginSuccessful = await login(email, password);

      // Check if the login is successful and navigate to the dashboard
      if (isLoginSuccessful) {
        // navigate("/configurations");
      } else {
        toast.error("Login failed. Please check your credentials.");
      }
    }
  };

  async function handleSSoSignIn(idToken: string) {
    const isLoginSuccessful = await ssoSignIn(idToken);
      if (isLoginSuccessful) {
        navigate("/configurations");
      } else {
        toast.error("Login failed. Please check your credentials.");
      }
    
  };

  return (
    <SignInContainer direction="column" justifyContent="space-between">
      {/* <ColorModeSelect sx={{ position: "fixed", top: "1rem", right: "1rem" }} /> */}
      <Card variant="outlined" sx={{borderRadius: 4}}>
        <SitemarkIcon />
        <Typography
          component="h1"
          variant="h4"
          sx={{ width: "100%", fontSize: "clamp(2rem, 10vw, 2.15rem)" }}
        >
          Sign in
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: 2,
          }}
        >
          {/* <FormControl>
            <FormLabel htmlFor="email">Email</FormLabel>
            <TextField
              error={emailError}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              helperText={emailErrorMessage}
              id="email"
              type="email"
              name="email"
              placeholder="your@email.com"
              autoComplete="email"
              autoFocus
              required
              fullWidth
              variant="outlined"
              color={emailError ? "error" : "primary"}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="password">Password</FormLabel>
            <TextField
              error={passwordError}
              helperText={passwordErrorMessage}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              name="password"
              placeholder="••••••"
              type="password"
              id="password"
              autoComplete="current-password"
              required
              fullWidth
              variant="outlined"
              color={passwordError ? "error" : "primary"}
            />
          </FormControl>
          <ForgotPassword open={open} handleClose={handleClose} />
          <Button type="submit" fullWidth variant="contained" color="secondary">
            Sign in
          </Button>
          <Link
            component="button"
            type="button"
            onClick={handleClickOpen}
            variant="body2"
            sx={{ alignSelf: "center" }}
          >
            Forgot your password?
          </Link>
          <Box p={2} display='flex' justifyContent='center' alignItems='center'>
            <Typography variant="body2">Or</Typography>
          </Box> */}
          <Box display='flex' justifyContent='center' alignItems='center'>
            <GoogleLogin
              onSuccess={(credentialResponse) => {
                if(credentialResponse.credential){
                  handleSSoSignIn(credentialResponse.credential);
                }
              }}
              onError={() => {
                console.log("Login Failed");
              }}
            />
            </Box>
            </Box>
      </Card>
    </SignInContainer>
  );

}