import { createContext, PropsWithChildren, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import httpClient from "../../utils/http";
import { useQuery } from "@tanstack/react-query";
import { Access, Feature } from "../../config";
import Cookies from 'js-cookie';

export interface User {
  id:         number;
  name:       string;
  email:      string;
  department: string;
  password:   string;
  isActive:   boolean;
  roleId:     number;
  picture: string | null;
  createdAt:  Date;
  updatedAt:  Date;
  role:      Role;
}

export interface Role {
  id:           number;
  name:         string;
  isSuperAdmin: boolean;
  permissions:  Permission[];
}

export interface Permission {
  feature: string;
  read:    boolean;
  write:   boolean;
  update:  boolean;
  delete:  boolean;
}


interface ISession {
  authToken: string | null;
  login: (email: string, password: string) => Promise<boolean>;
  ssoSignIn: (idToken: string) =>  Promise<boolean>;
  user: User | null;
  forgotPassword: (email: string) => Promise<boolean>;
  resetPassword: (token: string, password: string) => Promise<boolean>;
  logout: () => void;
  hasPermission: (feature: Feature,access: Access) => boolean;
}

const SessionContext = createContext<ISession | null>(null);

let _authToken = Cookies.get('authToken');

export function SessionProvider({ children }: PropsWithChildren) {
  
  const [authToken, setAuthtoken] = useState<string | null>(_authToken || null);
  // const [user, setUser] = useState<User | null>(null);
  // const {data: user} = useQuery<User>({
  //   queryKey: ['user',authToken],
  //   queryFn: async () => {
  //     return (await httpClient.get("/v1/admin/profile")).data?.data;
  //   }
  // })

  const { data: user, isSuccess } = useQuery<User>({
    queryKey: ["user", authToken],
    queryFn: async () => {
      return (await httpClient.get("/v1/admin/profile")).data?.data;
    },
    enabled: !!authToken, // Run only if authToken exists
  });
  
  useEffect(() => {
      if(authToken){
        Cookies.set("authToken",authToken);
      }else{
        localStorage.removeItem("authToken");
      }
  },[authToken]);

  const login = async (email: string, password: string): Promise<boolean> => {
    const response = await httpClient
      .post("/v1/admin/login", { email, password })
      .then(({ data }) => {
        if (data?.success) {
          const token = data.data.token;
          const user = data.data.user;

          setAuthtoken(token);
          // setUser(user);

          // Store token in localStorage
          Cookies.set("authToken", token);
          localStorage.setItem("user", JSON.stringify(user));

          toast.success("Login successful!");
          return true;
        } else {
          toast.error("Login failed. Please check your credentials.");
          return false;
        }
      })
      .catch((error) => {
        // toast.error("An error occurred while logging in.");
        console.error("Login error:", error);
        return false;
      });

    return response;
  };

  const ssoSignIn = async (idToken: string): Promise<boolean> => {
    const response = await httpClient
      .post("/v1/admin/login/sso", { provider: 'google',token: idToken })
      .then(({ data }) => {
        if (data?.success) {
          const token = data.data.token;
          setAuthtoken(token);
          // setUser(user);
          Cookies.set("authToken", token);
          // localStorage.setItem("user", JSON.stringify(user));
          toast.success("Login successful!");
          return true;
        } else {
          toast.error("Login failed. Please check your credentials.");
          return false;
        }
      })
      .catch((error) => {
        // toast.error("An error occurred while logging in.");
        console.error("Login error:", error);
        return false;
      });

    return response;
  };

  const logout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("user");
    setAuthtoken(null);
    // setUser(null);
    toast.success("Logged out successfully!");
  };
  


  const forgotPassword = async (email: string): Promise<boolean> => {
    return await httpClient
      .post("/v1/admin/forgot-password", { email })
      .then(({ data }) => {
        if (data?.success) {
          toast.success("A password reset link has been sent to your email.");
          return true;
        } else {
          toast.error("Failed to send reset link. Please try again.");
          return false;
        }
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again.");
        return false;
      });
  };
  

  const resetPassword = async (token: string, password: string): Promise<boolean> => {
    return await httpClient
      .patch("/v1/admin/forgot-password", { token, password })
      .then(({ data }) => {
        if (data?.success) {
          toast.success("Password reset successful!");
          return true;
        } else {
          toast.error(data.message || "Failed to reset password.");
          return false;
        }
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again.");
        return false;
      });
  };

  function hasPermission(feature: Feature,access: Access): boolean{
    if(user?.role.isSuperAdmin){
      return true;
    }else{
      let permission: Permission | null = user?.role.permissions.find(permission => permission.feature === feature)||null;

      if(permission){
        return permission[access];
      }else{
        return false;
      }
    }
  }

  return (
    <SessionContext.Provider
      value={{
        authToken,
        login,
        ssoSignIn,
        user: user || null,
        forgotPassword,
        resetPassword,
        logout,
        hasPermission
      }}
    >
      {children}
    </SessionContext.Provider>
  );
}

export default function useSession(): ISession {
  return useContext(SessionContext)!;
}
