const schema: any = {
    "$schema": "http://json-schema.org/draft-07/schema#",
    "definitions": {
        "APICallDefinition": {
            "properties": {
                "data": {
                    "properties": {
                        "body": {
                            "additionalProperties": {},
                            "type": "object"
                        },
                        "type": {
                            "enum": [
                                "formData",
                                "json",
                                "text"
                            ],
                            "type": "string"
                        }
                    },
                    "required": [
                        "body",
                        "type"
                    ],
                    "type": "object"
                },
                "headers": {
                    "additionalProperties": {
                        "type": "string"
                    },
                    "type": "object"
                },
                "method": {
                    "type": "string"
                },
                "params": {
                    "additionalProperties": {},
                    "type": "object"
                },
                "responseMapping": {
                    "additionalProperties": {
                        "type": "string"
                    },
                    "type": "object"
                },
                "url": {
                    "type": "string"
                }
            },
            "required": [
                "method",
                "responseMapping",
                "url"
            ],
            "type": "object"
        },
        "APICallStep": {
            "properties": {
                "apiCall": {
                    "$ref": "#/definitions/APICallDefinition"
                },
                "description": {
                    "type": "string"
                },
                "name": {
                    "type": "string"
                },
                "nextStep": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "stepId": {
                    "type": "string"
                },
                "type": {
                    "const": "api_call",
                    "type": "string"
                },
                "workflowId": {
                    "type": "string"
                }
            },
            "required": [
                "apiCall",
                "name",
                "nextStep",
                "stepId",
                "type"
            ],
            "type": "object"
        },
        "Case": {
            "properties": {
                "ADA_DocType__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "AGSS_Refund_Date__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "ARN__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "AVA_Result__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "AVA_Validated_Passenger__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "AVA_Validated_Sector__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "AccountId": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Account_First_Name__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Account_Last_Name__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Account_Type__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Actual_Date_Departure__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Ada_Chatter_ID__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Ada_Chatter_Token__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Age_from_Last_Modified__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Agency_Code__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "AirAsia_or_Subsidiaries__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Airline_Code_Navitaire__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Airline_Code_Second_Sector__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Airline_Code__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Airline_Non_AirAsia_Flights__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Airline_Refund_Field_Agency_ID__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Airline_Refund_Original_Decline_Code__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Airline_Refund_RPS_Case_Update__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Airport__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Alternate_Phone_Country_Code__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Alternate_Phone_Number__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Amount_Due_Currency__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Amount_Due__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Amount__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Amount_refund__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Approval_Code__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Arrival_AOC__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Arrival_Airport_Round_Trip__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Arrival_Airport_Third_Sector__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Arrival_Station__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Assistance_Received__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "BIG_Member_ID_Validation__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "BSB_Code__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Baggage_Status__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "BalanceDue__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Bank_Account_Holder_Gender__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Bank_Account_Holder_Home_Address_Non_En__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Bank_Account_Holder_Home_Address__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Bank_Account_Holder_Identity_Number_Pass__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Bank_Account_Holder_Telephone_Number__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Bank_Account_Number__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Bank_Address_Non_English__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Bank_Address__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Bank_Branch_Code__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Bank_Branch__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Bank_Branch_in_Chinese_language__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Bank_Code__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Bank_Holder_Name__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Bank_Holder_Name_in_Chinese_language__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Bank_Name__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Bank_Name_in_Chinese_language__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Booking_Creation_Date__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Booking_ID__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Booking_Number__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Booking_Payment_Mode__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Booking_Payment__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Booking_Status__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Booking_number_child__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Booking_service_fee__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Booking_service_fee_due_date__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Broadcast1__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Broadcast__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Bulk_Action__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Bulk_Email_Request__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Bulk_email_sent_date_time__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "BusinessHoursId": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "CAAC_Evidence__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "CAAC_Final_Reply__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "CAAC_ID__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "CAAC_Response_Sent__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "CAAC_Responsibility__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "CA_Notification__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "CA_Validity__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "CC_Conversion_Rate__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "CC_Conversion_Status__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "CC_Effective_Date__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "CC_Manual_Override__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "CHSS_LOB__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Call_Apex_to_set_case_owner__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Call_Center_Escalation_Team__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Call_Status__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Cancel_Delay_Reason_Second_Sector__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Cancel_Delay_Reason__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Cancellation_Delay_Reason__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Cancellation_ID__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Cancellation_Type__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "CaseNumber": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Case_Age_Hours__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Case_Age_in_Hours__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Case_Age_in_Mins__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Case_Closed_Within_24_hours__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Case_Currency__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Case_Language_API__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Case_Language__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Case_Owner_Manager__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Case_Owner_Role__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Case_Refund_Follow_Up__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Case_Source__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Change_AOC_Pool__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Chat_Transcript_Status_Picklist__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Chat_Transcript__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Citizen__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "City__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "City_in_Chinese_language__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "ClosedDate": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Closed_Time_24_hrs__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Comments": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Comments__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Compensation_Type__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "ContactEmail": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "ContactFax": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "ContactId": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "ContactMobile": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "ContactPhone": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Contact_First_Name__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Contact_Last_Name__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Contact_Number__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Corrective_Action_Amount__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Corrective_Action_Approval__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Corrective_Action_Approved_By__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Corrective_Action_Comment__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Corrective_Action_Status__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Corrective_Action_Submitted_By__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Cost_Per_Channel_Chat__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Counter__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Country_of_Bank__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Country_of_Origin__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Country_of_residence__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "CreatedById": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "CreatedDate": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "CreatedDate_2__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Credit_Account_Validation__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "CurrencyIsoCode": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Currency_Conversion_Table__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Customer_Number__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Date_Issued__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Date_Time_Email_Sent__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Date_Time_Refund_Approval__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Date_Time_Refund_Posted__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Date_Time_Refund_Validated__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Day_by_Week__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Declined_Reason_Code__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Declined_Reason__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Delivery_Service__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Delivery_Time__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Departure_AOC__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Departure_Airport_Round_Trip__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Departure_Airport_Third_Sector__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Departure_Airport__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Departure_Date_and_Time__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Departure_Station__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Departure_date__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Deposit_due_date__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Description": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Disposition_Level_1__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Disposition_Level_2__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Disposition_Level_3__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Dispute_Deadline_Date__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Duration_Approved_Posted__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Effective_Date__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Email_Check_Box_Hotel_Refund_Pending__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Email_Content__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Email__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Email_for_Maldives_Refund_Status__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Email_sent__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Email_to_Guest__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "EntitlementId": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Exclude_Child_Refund__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Experience_with_other_delivery_platform__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Expiry_Date__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Family_name_Surname__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "FindPersonDetails__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "First_Booking_Number__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "First_Case_Owner_Change_In_Hours__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "First_Case_Owner_Change_In_Mins__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "First_Case_Owner_Change__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "First_Response_Completed__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Flight_Date__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Flight_Number_Second_Sector__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Flight_Number__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Flight_Route_to_be_Refunded__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Flight_Sector__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Flight_Sector_to_be_Credited__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Flight_Sector_to_be_Refunded__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Form_of_Corrective_Action__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Friend_Referral__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Full_payment_due_date__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Function_in_AirAsia__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Gender__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Given_name__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Greater_China_Region__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Groceries__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Guest_Email_ID_for_TA_booking__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Guest_Flying__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Guest_name__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Health_Question__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Hotel_Cancellation__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Hotel_Check_Out_Date__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Hotel_Check_in_Date__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Hotel_Email__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Hotel_Internal_Reference__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Hotel_Name__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Hotel_Send_Email_to__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Hours_to_close__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "How_did_you_hear_about_us__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "IFSC_Code__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Iban_Code__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Id": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Initiate_Transfer_to_B2B_Client_Support__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Initiate_Transfer_to_Back_Office__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "International_Bank_Code__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "International_Bank_Number__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "IsClosed": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "IsClosedOnCreate": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "IsDeleted": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "IsEscalated": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "IsStopped": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Issuance_of_Letter__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Issuing_bank_Disputed_Date__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "LC_Service_Recommend__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "LC_Used_Quality_of_Service__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "LC_Used_Service_Recommend__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Language": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "LastModifiedById": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "LastModifiedDate": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "LastReferencedDate": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "LastViewedDate": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Last_Follow_Up_Date__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Live_Chat_Service_Feedback__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Live_Chat_Used_Quality_of_Service_Score__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Live_Chat_Used_Service_Recommend_Score__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Log_Email__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Malaysia_National_ID__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Manual_Case_Send_to_RPS__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Manual_Contact_Email__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Manual_Contact_First_Name__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Manual_Contact_Last_Name__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "MasterRecordId": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "MilestoneStatus": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Milestone_to_be_missed__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Mobile_Phone_Country_Code__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Mobile_Phone_Number__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Most_Recent_Owner_Change__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "NRIC_Number__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Name_list_due_date__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Nationality__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "New_Arrival_Date_Second_Sector__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "New_Arrival_Date__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "New_Arrival_Time_Second_Sector__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "New_Arrival_Time__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "New_Arrive_Time__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "New_Customer_Post_or_Comment__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "New_Departure_Time__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "New_Email_on_Closed_Case__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "New_Flight_Date_Second_Sector__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "New_Flight_Date__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "New_Flight_Time_Second_Sector__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "New_Flight_Time__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Non_AG_Agency_ID__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Non_AirAsia_Flights_Travel_Insurance__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Number_of_Child_Cases__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Number_of_Closed_Child_Cases__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Number_of_Messages_Received__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Number_of_Messages_Sent__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Number_of_Reminder_Received__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Number_of_passenger__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "OSAT_Detractor__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "OSAT_Promoter__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "OSAT_Text__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "OSAT__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Officer_Approval_Comments__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Order_Confirmation_Number__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Order_Number__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Origin": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Original_Arrival_Date_Second_Sector__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Original_Arrival_Date__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Original_Arrival_Time_Second_Sector__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Original_Arrival_Time__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Original_Flight_Date_Second_Sector__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Original_Flight_Date_Third_Sector__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Original_Flight_Date__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Original_Flight_Time_Second_Sector__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Original_Flight_Time__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Other_Topics__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Out_of_SOP__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "OwnerId": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "OwnerIsInSocialCare__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "OwnerIsQueue__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Owning_Carrier_Code__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "PIR_Number__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "ParentId": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Parent_Case_Number__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Parent_child_case__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Passenger_List_to_be_Credited__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Passenger_List_to_be_Refunded__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Passenger_Status__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Passenger_to_be_Credited__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Passenger_to_be_Refunded__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Pax_Count__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Payment_Code_Description__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Payment_Code__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Payment_Details__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Payment_Id__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Payment_Status__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Payment_Type_Description__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Payment_Type_ID__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Payment_Type__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Phone__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Planned_Return_Date__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Pre_Flight_Notification_Sent__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Prediction__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Preferred_date__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Preflight_Notification_Sent__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Preflight_Notification__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Priority": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Province__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Province_in_Chinese_language__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Quality_of_Service__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Queue_Code__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Queue_Name__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Queue_Reservation__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "RBV_Case_Disposition_2__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "RBV_Case_Language__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "RBV_Hotel_Refund__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "RBV_R1_BIG_Member_ID_Number__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "RBV_R1_Case_Disposition__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "RBV_R1_Sub_Category_1__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "RBV_R1_Sub_Category_2__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "RBV_R1_Sub_Category_3__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "RBV_R1_Type__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "RBV_REFUND_Authentication_Number__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "RBV_REFUND_Bank_Host__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "RBV_REFUND_Card_Expiration__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "RBV_REFUND_Card_Holder_Name__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "RBV_REFUND_Card_Number__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "RBV_REFUND_Card_Type__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "RBV_REFUND_MID__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "RBV_REFUND_PGRN__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "RBV_REFUND_Transaction_Amount__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "RBV_REFUND_Transaction_Date__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "RPS_Refund_Indicator__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "RPS_Update_Date_Time__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Reason": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "RecordTypeId": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Record_Type_Name__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Ref_OMM_For_Applicable_Refund__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Reference_Number__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Referral_Reward__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "RefundID__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Refund_Amount_Type__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Refund_Amount__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Refund_Approval__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Refund_Case_Error_Code__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Refund_Case_Error_Description__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Refund_Currency_Code__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Refund_Decline_Code_Message__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Refund_Delay__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Refund_Initiated_email_sent__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Refund_Status__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Refund_Sub_Status__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Refund_Type__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Refund_Validated__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Reimbursement_Amount__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Reimbursement_Currency_Code__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Related_Departments__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Remarks__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Report_Made__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Requires_Call_back__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Response_Time__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Return_Flight_Details__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Return_date__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Risk_Fraud_Information_Requested__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Risk__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Route__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "SFPS_SCS_Attr1__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "SFPS_SCS_Attr2__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "SFPS_SCS_Attr3__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "SFPS_SCS_Attr4__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "SFPS_SCS_Attr5__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "SFPS_SCS_Attr6__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "SFPS_SCS_AvgResponseTime__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "SFPS_SCS_AvgUpdateTime__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "SFPS_SCS_Channel__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "SFPS_SCS_CumulativeResponseTime__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "SFPS_SCS_CumulativeUpdateTime__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "SFPS_SCS_FirstInboundPostAt__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "SFPS_SCS_FirstOutboundPostAt__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "SFPS_SCS_FirstResponseTime__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "SFPS_SCS_Influence__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "SFPS_SCS_Language__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "SFPS_SCS_LastInboundPostAt__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "SFPS_SCS_LastOutboundPostAt__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "SFPS_SCS_LastPostAt__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "SFPS_SCS_LastPostRecordCreatedAt__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "SFPS_SCS_NPS_Original__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "SFPS_SCS_NPS__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "SFPS_SCS_New_Post__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "SFPS_SCS_NumberInboundPosts__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "SFPS_SCS_NumberOutboundPosts__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "SFPS_SCS_NumberPosts__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "SFPS_SCS_NumberUpdatePosts__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "SFPS_SCS_Priority_Icon__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "SFPS_SCS_Priority__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "SFPS_SCS_Time_Span_NPS_Change__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "SFPS_SCS_Timestamp_NPS_Original__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "SFPS_SCS_Timestamp_NPS__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Santan_Contact_Phone__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Santan_Feedback_Type__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Santan_Outlet__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Santan_customer_name__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Scheduled_Date_Departure__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Sector_List_to_be_Credited__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Sector_List_to_be_Refunded__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Send_Bulk_Email__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Send_to_OMS__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Send_to_RPS__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Send_to_Refund_Team__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Service_Recommend__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "SlaExitDate": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "SlaStartDate": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Social_Care_FCR__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Social_Escalation_Trigger__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Sort_Code__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "SourceId": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Spacility__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Staff_ID__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Start_Back_Office_Milestone__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Start_First_Response_Milestone__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Start_Refund_Milestone__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Start_Subsequent_Milestone__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Status": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Status_New__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Status_Pending_Guest_Reply__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "StopStartDate": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Sub_Category_1__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Sub_Category_2__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Sub_Category_3__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Sub_Category_Data_Updated__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Subject": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Submit_for_Approval__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Submit_for_Refund_Maker__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "SuppliedCompany": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "SuppliedEmail": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "SuppliedName": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "SuppliedPhone": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Supplied_Title__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Survey_Sent__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Swift_Code__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "SystemModstamp": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Time_24_Hours__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "TotalCost__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Transaction_Id__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Transfer_to_B2B_Client_Support_Team__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Transfer_to_Back_Office_Team__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Treatment__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Type": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Type_of_email__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Type_of_enquiry__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Types_of_Letter__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Types_of_Offer__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Up_Sell_Amount__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Upsell_Items__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "User__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Vehicle_Type__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Verified_Guest_First_Name__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Verified_Guest_Last_Name__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Visitor_Live_Chat_Message_Count__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Visitor_Message_Count__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Wallet_ID__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Write_Off_Amount__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "Write_Off_Currency_Code__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "santan_Collection_ID__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "santan_Date_of_Visit__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "santan_Order_ID__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "santan_Time_of_Visit_Order__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "santan_other_comments__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "scs_twtSurveys__Auto_Survey_Job_Id__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "scs_twtSurveys__CustomFieldForFilter__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "scs_twtSurveys__DatetimeTestFilter__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "scs_twtSurveys__EngineeringReqNumber__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "scs_twtSurveys__Feedback_Completed_Date__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "scs_twtSurveys__Feedback_Completed__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "scs_twtSurveys__Feedback_Score_Text__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "scs_twtSurveys__Feedback_Score_Value__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "scs_twtSurveys__Feedback_Score__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "scs_twtSurveys__Feedback_Status__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "scs_twtSurveys__Feedback_Submited_Date__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "scs_twtSurveys__Feedback_Type__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "scs_twtSurveys__Language__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "scs_twtSurveys__Optional_User_Feedback_Text__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "scs_twtSurveys__PotentialLiability__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "scs_twtSurveys__Product__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "scs_twtSurveys__SLAViolation__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "scs_twtSurveys__Survey_Status_Message__c": {
                    "type": [
                        "null",
                        "string"
                    ]
                }
            },
            "type": "object"
        },
        "ConditionDefinition": {
            "properties": {
                "condition": {
                    "type": "string"
                },
                "nextStep": {
                    "type": "string"
                },
                "workflowId": {
                    "type": "string"
                }
            },
            "required": [
                "condition",
                "nextStep"
            ],
            "type": "object"
        },
        "ConditionStep": {
            "properties": {
                "conditions": {
                    "items": {
                        "$ref": "#/definitions/ConditionDefinition"
                    },
                    "type": "array"
                },
                "description": {
                    "type": "string"
                },
                "name": {
                    "type": "string"
                },
                "nextStep": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "stepId": {
                    "type": "string"
                },
                "type": {
                    "const": "condition",
                    "type": "string"
                },
                "workflowId": {
                    "type": "string"
                }
            },
            "required": [
                "conditions",
                "name",
                "nextStep",
                "stepId",
                "type"
            ],
            "type": "object"
        },
        "DropdownSelectionStep": {
            "properties": {
                "description": {
                    "type": "string"
                },
                "dropdown": {
                    "enum": [
                        "airports",
                        "cities",
                        "countries"
                    ],
                    "type": "string"
                },
                "message": {
                    "type": "string"
                },
                "name": {
                    "type": "string"
                },
                "nextStep": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "responseMapping": {
                    "additionalProperties": {
                        "type": "string"
                    },
                    "type": "object"
                },
                "stepId": {
                    "type": "string"
                },
                "type": {
                    "const": "dropdown_selection",
                    "type": "string"
                },
                "workflowId": {
                    "type": "string"
                }
            },
            "required": [
                "dropdown",
                "message",
                "name",
                "nextStep",
                "responseMapping",
                "stepId",
                "type"
            ],
            "type": "object"
        },
        "FileUploadStep": {
            "properties": {
                "description": {
                    "type": "string"
                },
                "message": {
                    "type": "string"
                },
                "name": {
                    "type": "string"
                },
                "nextStep": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "responseMapping": {
                    "additionalProperties": {
                        "type": "string"
                    },
                    "type": "object"
                },
                "stepId": {
                    "type": "string"
                },
                "type": {
                    "const": "file_upload",
                    "type": "string"
                },
                "workflowId": {
                    "type": "string"
                }
            },
            "required": [
                "message",
                "name",
                "nextStep",
                "responseMapping",
                "stepId",
                "type"
            ],
            "type": "object"
        },
        "FlightSelectionStep": {
            "properties": {
                "data": {
                    "type": "string"
                },
                "dataMapping": {
                    "properties": {
                        "bookingNumber": {
                            "type": "string"
                        },
                        "flightArrCode": {
                            "type": "string"
                        },
                        "flightArrScheduled": {
                            "type": "string"
                        },
                        "flightDate": {
                            "type": "string"
                        },
                        "flightDepCode": {
                            "type": "string"
                        },
                        "flightDepScheduled": {
                            "type": "string"
                        },
                        "flightNumber": {
                            "type": "string"
                        },
                        "flightPNR": {
                            "type": "string"
                        }
                    },
                    "required": [
                        "flightArrCode",
                        "flightArrScheduled",
                        "flightDate",
                        "flightDepCode",
                        "flightDepScheduled",
                        "flightNumber"
                    ],
                    "type": "object"
                },
                "description": {
                    "type": "string"
                },
                "message": {
                    "type": "string"
                },
                "name": {
                    "type": "string"
                },
                "nextStep": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "responseMapping": {
                    "additionalProperties": {
                        "type": "string"
                    },
                    "type": "object"
                },
                "stepId": {
                    "type": "string"
                },
                "type": {
                    "const": "flight_selection",
                    "type": "string"
                },
                "workflowId": {
                    "type": "string"
                }
            },
            "required": [
                "data",
                "dataMapping",
                "message",
                "name",
                "nextStep",
                "responseMapping",
                "stepId",
                "type"
            ],
            "type": "object"
        },
        "FlightStatusCardStep": {
            "properties": {
                "data": {
                    "properties": {
                        "flightArr": {
                            "type": "string"
                        },
                        "flightArrActual": {
                            "type": [
                                "null",
                                "string"
                            ]
                        },
                        "flightArrCode": {
                            "type": "string"
                        },
                        "flightArrEstimated": {
                            "type": [
                                "null",
                                "string"
                            ]
                        },
                        "flightArrScheduled": {
                            "type": "string"
                        },
                        "flightDate": {
                            "type": "string"
                        },
                        "flightDep": {
                            "type": "string"
                        },
                        "flightDepActual": {
                            "type": [
                                "null",
                                "string"
                            ]
                        },
                        "flightDepCode": {
                            "type": "string"
                        },
                        "flightDepEstimated": {
                            "type": [
                                "null",
                                "string"
                            ]
                        },
                        "flightDepScheduled": {
                            "type": "string"
                        },
                        "flightNumber": {
                            "type": "string"
                        },
                        "flightStatus": {
                            "type": "string"
                        },
                        "utcFlightArrival": {
                            "type": [
                                "null",
                                "string"
                            ]
                        },
                        "utcFlightDepart": {
                            "type": [
                                "null",
                                "string"
                            ]
                        }
                    },
                    "required": [
                        "flightArr",
                        "flightArrCode",
                        "flightArrScheduled",
                        "flightDate",
                        "flightDep",
                        "flightDepCode",
                        "flightDepScheduled",
                        "flightNumber",
                        "flightStatus"
                    ],
                    "type": "object"
                },
                "description": {
                    "type": "string"
                },
                "message": {
                    "type": "string"
                },
                "name": {
                    "type": "string"
                },
                "nextStep": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "stepId": {
                    "type": "string"
                },
                "type": {
                    "const": "flight_status",
                    "type": "string"
                },
                "workflowId": {
                    "type": "string"
                }
            },
            "required": [
                "data",
                "message",
                "name",
                "nextStep",
                "stepId",
                "type"
            ],
            "type": "object"
        },
        "FormStep": {
            "properties": {
                "data": {
                    "items": {
                        "properties": {
                            "displayWhen": {
                                "anyOf": [
                                    {
                                        "properties": {
                                            "field": {
                                                "type": "string"
                                            },
                                            "is": {
                                                "type": "boolean"
                                            }
                                        },
                                        "required": [
                                            "field",
                                            "is"
                                        ],
                                        "type": "object"
                                    },
                                    {
                                        "type": "null"
                                    }
                                ]
                            },
                            "id": {
                                "type": "string"
                            },
                            "label": {
                                "type": "string"
                            },
                            "placeholder": {
                                "type": "string"
                            },
                            "regex": {
                                "type": "string"
                            },
                            "required": {
                                "type": "boolean"
                            },
                            "type": {
                                "type": "string"
                            }
                        },
                        "required": [
                            "id",
                            "label",
                            "required",
                            "type"
                        ],
                        "type": "object"
                    },
                    "type": "array"
                },
                "description": {
                    "type": "string"
                },
                "message": {
                    "type": "string"
                },
                "name": {
                    "type": "string"
                },
                "nextStep": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "responseMapping": {
                    "additionalProperties": {
                        "type": "string"
                    },
                    "type": "object"
                },
                "stepId": {
                    "type": "string"
                },
                "type": {
                    "const": "form",
                    "type": "string"
                },
                "workflowId": {
                    "type": "string"
                }
            },
            "required": [
                "data",
                "message",
                "name",
                "nextStep",
                "responseMapping",
                "stepId",
                "type"
            ],
            "type": "object"
        },
        "InputStep": {
            "properties": {
                "checkUserInputHistory": {
                    "type": "boolean"
                },
                "description": {
                    "type": "string"
                },
                "inputs": {
                    "items": {
                        "type": "string"
                    },
                    "type": "array"
                },
                "message": {
                    "type": "string"
                },
                "name": {
                    "type": "string"
                },
                "nextStep": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "stepId": {
                    "type": "string"
                },
                "type": {
                    "const": "input",
                    "type": "string"
                },
                "workflowId": {
                    "type": "string"
                }
            },
            "required": [
                "inputs",
                "message",
                "name",
                "nextStep",
                "stepId",
                "type"
            ],
            "type": "object"
        },
        "LiveAgentTransferStep": {
            "properties": {
                "data": {
                    "$ref": "#/definitions/Case"
                },
                "description": {
                    "type": "string"
                },
                "fallbackStep": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "message": {
                    "type": "string"
                },
                "name": {
                    "type": "string"
                },
                "stepId": {
                    "type": "string"
                },
                "type": {
                    "const": "live_agent_ransfer",
                    "type": "string"
                },
                "workflowId": {
                    "type": "string"
                }
            },
            "required": [
                "data",
                "fallbackStep",
                "message",
                "name",
                "stepId",
                "type"
            ],
            "type": "object"
        },
        "MessageStep": {
            "properties": {
                "description": {
                    "type": "string"
                },
                "message": {
                    "type": "string"
                },
                "name": {
                    "type": "string"
                },
                "nextStep": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "options": {
                    "items": {
                        "type": "string"
                    },
                    "type": "array"
                },
                "stepId": {
                    "type": "string"
                },
                "type": {
                    "const": "message",
                    "type": "string"
                },
                "workflowId": {
                    "type": "string"
                }
            },
            "required": [
                "message",
                "name",
                "nextStep",
                "stepId",
                "type"
            ],
            "type": "object"
        },
        "OptionsStep": {
            "properties": {
                "description": {
                    "type": "string"
                },
                "message": {
                    "type": "string"
                },
                "name": {
                    "type": "string"
                },
                "options": {
                    "items": {
                        "properties": {
                            "label": {
                                "type": "string"
                            },
                            "nextStep": {
                                "type": [
                                    "null",
                                    "string"
                                ]
                            },
                            "value": {
                                "type": "string"
                            },
                            "workflowId": {
                                "type": "string"
                            }
                        },
                        "required": [
                            "label",
                            "nextStep",
                            "value"
                        ],
                        "type": "object"
                    },
                    "type": "array"
                },
                "responseMapping": {
                    "additionalProperties": {
                        "type": "string"
                    },
                    "type": "object"
                },
                "stepId": {
                    "type": "string"
                },
                "type": {
                    "const": "options",
                    "type": "string"
                }
            },
            "required": [
                "message",
                "name",
                "options",
                "stepId",
                "type"
            ],
            "type": "object"
        },
        "PassengerSelectionStep": {
            "properties": {
                "data": {
                    "type": "string"
                },
                "dataMapping": {
                    "properties": {
                        "name": {
                            "type": "string"
                        },
                        "passengerKey": {
                            "type": "string"
                        }
                    },
                    "required": [
                        "name",
                        "passengerKey"
                    ],
                    "type": "object"
                },
                "description": {
                    "type": "string"
                },
                "message": {
                    "type": "string"
                },
                "name": {
                    "type": "string"
                },
                "nextStep": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "responseMapping": {
                    "additionalProperties": {
                        "type": "string"
                    },
                    "type": "object"
                },
                "stepId": {
                    "type": "string"
                },
                "type": {
                    "const": "passenger_selection",
                    "type": "string"
                },
                "workflowId": {
                    "type": "string"
                }
            },
            "required": [
                "data",
                "dataMapping",
                "message",
                "name",
                "nextStep",
                "responseMapping",
                "stepId",
                "type"
            ],
            "type": "object"
        },
        "RedirectStep": {
            "properties": {
                "description": {
                    "type": "string"
                },
                "name": {
                    "type": "string"
                },
                "nextStep": {
                    "type": "string"
                },
                "stepId": {
                    "type": "string"
                },
                "type": {
                    "const": "redirect",
                    "type": "string"
                },
                "workflowId": {
                    "type": "string"
                }
            },
            "required": [
                "name",
                "nextStep",
                "stepId",
                "type",
                "workflowId"
            ],
            "type": "object"
        },
        "RefundFormStep": {
            "properties": {
                "data": {
                    "properties": {
                        "currency": {
                            "type": "string"
                        },
                        "prefill": {
                            "additionalProperties": {
                                "type": "string"
                            },
                            "type": "object"
                        },
                        "requiredParams": {
                            "type": "string"
                        }
                    },
                    "required": [
                        "currency",
                        "requiredParams"
                    ],
                    "type": "object"
                },
                "description": {
                    "type": "string"
                },
                "message": {
                    "type": "string"
                },
                "name": {
                    "type": "string"
                },
                "nextStep": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "responseMapping": {
                    "additionalProperties": {
                        "type": "string"
                    },
                    "type": "object"
                },
                "stepId": {
                    "type": "string"
                },
                "type": {
                    "const": "refund_form",
                    "type": "string"
                },
                "workflowId": {
                    "type": "string"
                }
            },
            "required": [
                "data",
                "message",
                "name",
                "nextStep",
                "responseMapping",
                "stepId",
                "type"
            ],
            "type": "object"
        },
        "SetVariablesStep": {
            "properties": {
                "description": {
                    "type": "string"
                },
                "name": {
                    "type": "string"
                },
                "nextStep": {
                    "type": [
                        "null",
                        "string"
                    ]
                },
                "stepId": {
                    "type": "string"
                },
                "type": {
                    "const": "set_variables",
                    "type": "string"
                },
                "variables": {
                    "additionalProperties": {},
                    "type": "object"
                },
                "workflowId": {
                    "type": "string"
                }
            },
            "required": [
                "name",
                "nextStep",
                "stepId",
                "type",
                "variables"
            ],
            "type": "object"
        },
        "Step": {
            "anyOf": [
                {
                    "$ref": "#/definitions/InputStep"
                },
                {
                    "$ref": "#/definitions/ConditionStep"
                },
                {
                    "$ref": "#/definitions/APICallStep"
                },
                {
                    "$ref": "#/definitions/MessageStep"
                },
                {
                    "$ref": "#/definitions/OptionsStep"
                },
                {
                    "$ref": "#/definitions/FormStep"
                },
                {
                    "$ref": "#/definitions/RefundFormStep"
                },
                {
                    "$ref": "#/definitions/SetVariablesStep"
                },
                {
                    "$ref": "#/definitions/FlightSelectionStep"
                },
                {
                    "$ref": "#/definitions/PassengerSelectionStep"
                },
                {
                    "$ref": "#/definitions/FlightStatusCardStep"
                },
                {
                    "$ref": "#/definitions/FileUploadStep"
                },
                {
                    "$ref": "#/definitions/LiveAgentTransferStep"
                },
                {
                    "$ref": "#/definitions/DropdownSelectionStep"
                },
                {
                    "$ref": "#/definitions/RedirectStep"
                }
            ]
        }
    },
    "properties": {
        "description": {
            "type": "string"
        },
        "executionType": {
            "enum": [
                "main",
                "subflow"
            ],
            "type": "string"
        },
        "firstStep": {
            "type": "string"
        },
        "id": {
            "type": "string"
        },
        "inputs": {
            "additionalProperties": {
                "properties": {
                    "defaultValue": {},
                    "description": {
                        "type": "string"
                    },
                    "regex": {
                        "type": "string"
                    },
                    "type": {
                        "enum": [
                            "ARRAY",
                            "BOOLEAN",
                            "NUMBER",
                            "OBJECT",
                            "STRING"
                        ],
                        "type": "string"
                    }
                },
                "required": [
                    "defaultValue",
                    "description",
                    "type"
                ],
                "type": "object"
            },
            "type": "object"
        },
        "name": {
            "type": "string"
        },
        "prompts": {
            "items": {
                "type": "string"
            },
            "type": "array"
        },
        "steps": {
            "items": {
                "$ref": "#/definitions/Step"
            },
            "type": "array"
        },
        "variables": {
            "additionalProperties": {},
            "type": "object"
        }
    },
    "required": [
        "firstStep",
        "id",
        "inputs",
        "name",
        "steps",
        "variables"
    ],
    "type": "object"
};

export default schema;
