import { Box, Switch, Typography, useTheme } from "@mui/material";
import MainLayout from "../../layouts/main/main_layout";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import httpClient from "../../utils/http";
import dayjs from "dayjs";

export default function Workflows() {
  const theme = useTheme();
  const { data: workflows } = useQuery({
    queryKey: ["workflows"],
    queryFn: async () => {
      let { data } = await httpClient.get("/v1/admin/workflows");
      return data.data;
    },
  });

  let columns: GridColDef[] = [
    {
      field: "workflowId",
      headerName: "Workflow ID",
      flex: 2,
      renderCell: (data) => {
        return (
          <Typography
            fontWeight={600}
            height={"100%"}
            display="flex"
            alignItems="center"
          >
            {data.row.workflowId || ""}
          </Typography>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      flex: 2,
      renderCell: (data) => {
        return data.row.testJson.name || "";
      },
    },
    {
      field: "description",
      headerName: "Description",
      flex: 3,
      renderCell: (data) => {
        return data.row.testJson.description || "";
      },
    },
    {
      field: "isActive",
      headerName: "Active",
      flex: 1,
      renderCell: (data) => {
        return <Switch />
      },
    },
    { field: "createdAt", headerName: "Created At", flex: 1,renderCell: (data) => {
      return dayjs(data.row.createdAt).format("HH:mm, DD MMM");
    }, },
    { field: "updatedAt", headerName: "Updated At", flex: 1,renderCell: (data) => {
      return dayjs(data.row.updatedAt).format("HH:mm, DD MMM");
    }, },
  ];

  return (
    <MainLayout>
      <Box sx={{ width: "100%" }}>
        <Box display={"flex"} justifyContent={"space-between"} mt={2} mb={2}>
          <Typography component="h2" variant="h6">
            Workflows
          </Typography>
        </Box>

        <DataGrid
          // disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          disableRowSelectionOnClick
          rows={workflows}
          columns={columns}
          getRowId={(row) => row.id}
          slots={{ toolbar: GridToolbar }}
          sx={{ height: "88vh" }}
        />
      </Box>
    </MainLayout>
  );
}
