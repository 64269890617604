import React from "react";
import {
  Box,
  Typography,
  Divider,
  IconButton,
  useTheme,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { useQuery } from "@tanstack/react-query";
import httpClient from "../../utils/http";
import { blue, green, grey } from "@mui/material/colors";
import dayjs from "dayjs";
import Markdown from "react-markdown";

interface RightSideLayoutProps {
  selectedPerson: string | null;
  data:
    | {
        sessionId: string;
        userId: string;
        username: string;
        firstName: string;
        lastName: string;
        createdAt: string;
      }[]
    | null;
}

export interface Session {
  sessionId: string;
  device: string;
  browser: string;
  language: string;
  userAgent: string | null;
  lat: number | null;
  lon: number | null;
  ip: string | null;
  rating: number | null;
  agentRating: number | null;
  resolvedEnquiryFeedback: boolean | null;
  feedback: string | null;
  user: null | {
    userId: string;
    username: string;
    firstName: string;
    lastName: string;
  };
  conversation: Conversation[];
}

export interface Conversation {
  id: string;
  senderType: string;
  message: string;
  createdAt: Date;
}

const RightSideLayout: React.FC<RightSideLayoutProps> = ({
  selectedPerson,
  // data,
}) => {
  const { data } = useQuery<Session>({
    queryKey: ["conversations", selectedPerson],
    queryFn: async () => {
      return (await httpClient.get(`/v1/admin/conversation/${selectedPerson}`))
        .data.data;
    },
  });
  const theme = useTheme();

  if (!data) return <></>;

  return (
    <Box display="flex" height={"100%"}>
      {selectedPerson && data && (
        <Box
          flex={1}
          sx={{
            display: "flex",
            flexDirection: "column",
            marginBottom: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 2,
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {data.user
                ? `${data.user.firstName} ${data.user.lastName}`
                : "Anonymous"}
              {/* <Typography variant="body2">
                {data.browser} {data.device} {data.language}
                </Typography> */}
            </Typography>
          </Box>
          <Divider sx={{}} />

          <Box
            sx={{
              borderRadius: "10px",
              padding: 2,
              maxHeight: "calc(100vh - 100px)",
              overflowY: "hidden",
              scrollbarWidth: "thin",
              scrollbarGutter: "stable",
              paddingRight: { xs: "12px", sm: "16px" },
              "&:hover": {
                overflowY: "auto",
              },
            }}
          >
            {data?.conversation?.map((chat, index) => {
              let isNotSameDay = true;
              if (index !== 0) {
                isNotSameDay = !dayjs(
                  data.conversation[index - 1].createdAt
                ).isSame(chat.createdAt, "day");
              }
              if (chat.senderType === "bot" || chat.senderType === "agent") {
                return (
                  <Box>
                    {isNotSameDay && (
                      <Box p={2}>
                        <Typography align="center">
                          {dayjs(chat.createdAt).format("DD-MM-YYYY")}
                        </Typography>
                      </Box>
                    )}
                    <Box
                      m={2}
                      display="flex"
                      justifyContent="flex-start"
                      alignItems={"center"}
                    >
                      <Box
                        borderRadius={2}
                        px={2}
                        py={1}
                        bgcolor={
                          chat.senderType === "agent" ? blue[50] : grey[100]
                        }
                        sx={{ maxWidth: "max( 300px , 30vw )" }}
                        mr={2}
                      >
                        <Typography variant="body1">
                          <Markdown>{chat.message}</Markdown>
                        </Typography>
                      </Box>
                      <Typography variant="caption">
                        {dayjs(chat.createdAt).format("HH:mm A")}
                      </Typography>
                    </Box>
                  </Box>
                );
              } else if (chat.senderType === "user") {
                return (
                  <Box>
                    {isNotSameDay && (
                      <Box p={2}>
                        <Typography align="center">
                          {dayjs(chat.createdAt).format("DD-MM-YYYY")}
                        </Typography>
                      </Box>
                    )}
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      m={2}
                      alignItems="center"
                    >
                      <Typography variant="caption">
                        {dayjs(chat.createdAt).format("HH:mm A")}
                      </Typography>
                      <Box
                        ml={2}
                        borderRadius={2}
                        px={2}
                        py={1}
                        bgcolor={green[50]}
                        sx={{ maxWidth: "max( 300px , 30vw )" }}
                      >
                        <Typography variant="body1"><Markdown>{chat.message}</Markdown></Typography>
                      </Box>
                    </Box>
                  </Box>
                );
              } else if (chat.senderType === "system") {
                return (
                  <Box>
                    {isNotSameDay && (
                      <Box p={2}>
                        <Typography align="center" fontWeight="500">
                          {dayjs(chat.createdAt).format("DD-MM-YYYY")}
                        </Typography>
                      </Box>
                    )}
                    <Box display="flex" justifyContent="center" m={2}>
                      <Box
                        borderRadius={2}
                        px={2}
                        py={1}
                        textAlign="center"
                        sx={{ maxWidth: "max( 300px , 40vw )" }}
                        fontWeight="500"
                      >
                        <Typography variant="body1"><Markdown>{chat.message}</Markdown></Typography>
                      </Box>
                    </Box>
                  </Box>
                );
              }
            })}
          </Box>
        </Box>
      )}

      {selectedPerson && data && (
        <Box
          height={`100%`}
          width={320}
          borderLeft={`1px solid ${theme.palette.divider}`}
        >
          <Box padding={2} display={"flex"} flexDirection={"column"}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Details
            </Typography>
          </Box>
          <Divider />
          <Box flex={1} height={"100%"} overflow={"auto"}>
            <List>
              <ListItem>
                <ListItemText
                  primary={"Session ID"}
                  secondary={data.sessionId}
                />
              </ListItem>
              <ListItem>
                <ListItemText primary={"Language"} secondary={data.language} />
              </ListItem>
              <ListItem>
                <ListItemText primary={"Device"} secondary={data.device} />
              </ListItem>
              <ListItem>
                <ListItemText primary={"IP Address"} secondary={data.ip} />
              </ListItem>
              <ListItem>
                <ListItemText primary={"Browser"} secondary={data.browser} />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={"User Agent"}
                  secondary={data.userAgent}
                />
              </ListItem>
              {data.lat && data.lon ? (
                <ListItem dense>
                  <ListItemText
                    primary={"Location"}
                    secondaryTypographyProps={{ padding: 0, margin: 0 }}
                    secondary={
                      <Box mt={1} overflow={"hidden"} borderRadius={2}>
                        <iframe
                          title="map"
                          frameBorder={0}
                          src={`https://maps.google.com/maps?q=${data.lat},${data.lon}&output=embed`}
                        ></iframe>
                      </Box>
                    }
                  />
                </ListItem>
              ) : null}

              <Divider />
              <ListItem>
                <ListItemText
                  primary={"Ratings"}
                  secondary={data.rating != null ? `${data.rating}/5` : "-"}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={"Agent Ratings"}
                  secondary={
                    data.agentRating != null ? `${data.agentRating}/5` : "-"
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={"Was The Enquiry Easily Resolved?"}
                  secondary={
                    typeof data.resolvedEnquiryFeedback == "boolean" ? data.resolvedEnquiryFeedback ? "Yes" : "No":"-"
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={"Feedback"}
                  secondary={data.feedback || "-"}
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText
                  primary={"User ID"}
                  secondary={data.user?.userId || "-"}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={"Email"}
                  secondary={data.user?.username || "-"}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={"First Name"}
                  secondary={data.user?.firstName || "-"}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={"Last Name"}
                  secondary={data?.user?.lastName || "-"}
                />
              </ListItem>
            </List>
            <Box height={100}></Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default RightSideLayout;
