import React from "react";
import Editor, { EditorProps, OnMount } from "@monaco-editor/react";
import { Box } from "@mui/material";
import schema from "./schema";

export default function MonacoJsonEditor({
  workflowId,
  value,
  onChange,
}: {
  workflowId: string,
  value: any,
  onChange: (jsonString: string) => void;
}) {
  const handleEditorMount: OnMount = (editor, monaco) => {
    try {
      schema.properties.id = {"const": workflowId};
      monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
        validate: true,
        comments: "error",
        allowComments: true,
        enableSchemaRequest: true,
        trailingCommas: "error",
        schemaValidation: "error",
        schemas: [
          {
            uri: "https://my-app/schema.json",
            fileMatch: ["*"],
            schema: schema,
          },
        ],
      });
    } catch (error) {
      console.error("Failed to set Monaco theme:", error);
    }
  };

  const editorOptions: EditorProps["options"] = {
    automaticLayout: true,
    formatOnType: true,
    formatOnPaste: true,
  };

  return (
    <Box sx={{ height: "calc( 100vh - 200px )" }}>
      <Editor
        value={value}
        language="json"
        defaultLanguage="json"
        // theme="custom-dark"
        options={editorOptions}
        onMount={handleEditorMount}
        onChange={(json) => onChange(json || "")}
      />
    </Box>
  );
}
