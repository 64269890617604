import React, { useState } from "react";
import {
  Box,
  Button,
  Switch,
  Typography,
  IconButton,
  Dialog,
  Grid2,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormLabel,
  TextField,
  FormControlLabel,
  Grid,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import DeleteIcon from "@mui/icons-material/Delete";
import { Edit } from "@mui/icons-material";
import MainLayout from "../../layouts/main/main_layout";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import useFeedback from "../../provider/feedback/feedback_provider";
import httpClient from "../../utils/http";

type Incident = {
  id: number;
  name: string;
  alertMessage: string;
  description: string;
  startAt: string;
  endAt: string;
  isActive: boolean;
  refund: boolean;
  credit: boolean;
  moveFlight: boolean;
  connectToAllStar: boolean;
};

export default function Incident() {
  const { showSnackbar } = useFeedback();
  const [addIncident, setAddIncident] = useState<boolean>(false);
  const [editIncidentId, setEditIncidentId] = useState<number | null>(null);
  const [open, setOpen] = useState(false);
  const [formValues, setFormValues] = useState({
    id: 0,
    name: "",
    alertMessage: "",
    description: "",
    startAt: "",
    endAt: "",
    isActive: false,
    refund: false,
    credit: false,
    moveFlight: false,
    connectToAllStar: false,
  });
  const queryClient = useQueryClient();

  const {
    data: incidents,
    isLoading,
    isError,
    refetch: refetchIncidents,
  } = useQuery({
    queryKey: ["incidents"],
    queryFn: async () => {
      const response = await httpClient.get("/v1/admin/incidents");
      return response.data.data.map((incident: Incident) => ({
        ...incident,
        startAt: dayjs(incident.startAt).format("DD MMM YYYY hh:mm A"),
        endAt: dayjs(incident.endAt).format("DD MMM YYYY hh:mm A"),
        isActive: incident.isActive,
      }));
    },
  });

  const { mutate: createIncident } = useMutation({
    mutationFn: async () => {
      const response = await httpClient.post("/v1/admin/incidents", {
        name: formValues.name,
        alertMessage: formValues.alertMessage,
        description: formValues.description,
        startAt: formValues.startAt,
        endAt: formValues.endAt,
        isActive: formValues.isActive,
        refund: formValues.refund,
        credit: formValues.credit,
        moveFlight: formValues.moveFlight,
        connectToAllStar: formValues.connectToAllStar,
        // createdAt: new Date().toISOString(),
        // updatedAt: new Date().toISOString(),
      });

      return response.data;
    },
    onSuccess: () => {
      refetchIncidents(); // Refetch the incidents list
      showSnackbar("Incident added successfully!", "success");
      setOpen(false);
      setFormValues({
        id: 0,
        name: "",
        alertMessage: "",
        description: "",
        startAt: "",
        endAt: "",
        isActive: false,
        refund: false,
        credit: false,
        moveFlight: false,
        connectToAllStar: false,
      });
    },
    onError: (error: any) => {
      showSnackbar(
        `Failed to add incident: ${
          error?.response?.data?.message || "Unknown error"
        }`,
        "error"
      );
    },
  });

  const toggleMutation = useMutation({
    mutationFn: async ({
      id,
      field,
      value,
    }: {
      id: number;
      field: keyof Incident;
      value: boolean;
    }) => {
      const response = await httpClient.patch(`/v1/admin/incidents/${id}`, {
        [field]: value,
      });
      return response.data;
    },
    onSuccess: () => {
      refetchIncidents();
      setOpen(false);
      showSnackbar("Incident status updated successfully!", "success");
    },
    onError: (error: any) => {
      showSnackbar(
        `Failed to update incident: ${
          error?.response?.data?.message || "Unknown error"
        }`,
        "error"
      );
    },
  });

  const updateIncidentMutation = useMutation({
    mutationFn: async ({
      id,
      updatedValues,
    }: {
      id: number;
      updatedValues: Partial<Incident>;
    }) => {
      const response = await httpClient.patch(
        `/v1/admin/incidents/${id}`,
        updatedValues
      );
      return response.data;
    },
    onSuccess: () => {
      refetchIncidents(); // Fetch latest data after update
      showSnackbar("Incident updated successfully!", "success");
      setOpen(false);
      setEditIncidentId(null);
    },
    onError: (error: any) => {
      showSnackbar(
        `Failed to update incident: ${
          error?.response?.data?.message || "Unknown error"
        }`,
        "error"
      );
    },
  });

  const { mutate: deleteIncident } = useMutation({
    mutationFn: async (id: number) => {
      const response = await httpClient.delete(`/v1/admin/incidents/${id}`);
      return response.data;
    },
    onSuccess: () => {
      refetchIncidents();
      showSnackbar("Incident deleted successfully!", "success");
    },
    onError: (error: any) => {
      showSnackbar(
        `Failed to delete incident: ${
          error?.response?.data?.message || "Unknown error"
        }`,
        "error"
      );
    },
  });

  const resetForm = () => {
    setFormValues({
      id: 0,
      name: "",
      alertMessage: "",
      description: "",
      startAt: "",
      endAt: "",
      isActive: false,
      refund: false,
      credit: false,
      moveFlight: false,
      connectToAllStar: false,
    });
  };

  const handleEditIncident = (incident: Incident) => {
    setFormValues({
      id: incident.id,
      name: incident.name,
      alertMessage: incident.alertMessage,
      description: incident.description,
      startAt: incident.startAt,
      endAt: incident.endAt,
      isActive: incident.isActive,
      refund: incident.refund,
      credit: incident.credit,
      moveFlight: incident.moveFlight,
      connectToAllStar: incident.connectToAllStar,
    });
    setEditIncidentId(incident.id);
    setOpen(true);
    setAddIncident(true);
  };

  const columns: GridColDef[] = [
    // { field: "id", headerName: "ID",
    //   //  width: 80
    //   flex: 1,
    //   },
    {
      field: "name",
      headerName: "Name",
      //  width: 150
      flex: 1,
    },
    {
      field: "alertMessage",
      headerName: "Message",
      // width: 200
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      //  width: 200
      flex: 1,
    },
    {
      field: "isActive",
      headerName: "Active",
      // width: 120,
      flex: 1,
      renderCell: (params) => (
        <Switch
          checked={params.value}
          onChange={() =>
            toggleMutation.mutate({
              id: params.row.id,
              field: "isActive",
              value: !params.row.isActive, // Toggle the current value
            })
          }
        />
      ),
    },
    {
      field: "refund",
      headerName: "Refund",
      // width: 120,
      flex: 1,
      renderCell: (params) => (
        <Switch
          checked={params.value}
          onChange={() =>
            toggleMutation.mutate({
              id: params.row.id,
              field: "refund",
              value: !params.row.refund, // Toggle the current value
            })
          }
        />
      ),
    },
    {
      field: "credit",
      headerName: "Credit",
      // width: 120,
      flex: 1,
      renderCell: (params) => (
        <Switch
          checked={params.value}
          onChange={() =>
            toggleMutation.mutate({
              id: params.row.id,
              field: "credit",
              value: !params.row.credit, // Toggle the current value
            })
          }
        />
      ),
    },
    {
      field: "moveFlight",
      headerName: "Move Flight",
      // width: 150,
      flex: 1,
      renderCell: (params) => (
        <Switch
          checked={params.value}
          onChange={() =>
            toggleMutation.mutate({
              id: params.row.id,
              field: "moveFlight",
              value: !params.row.moveFlight, // Toggle the current value
            })
          }
        />
      ),
    },
    {
      field: "connectToAllStar",
      headerName: "Connect to Allstar",
      // width: 180,
      flex: 1,
      renderCell: (params) => (
        <Switch
          checked={params.value}
          onChange={() =>
            toggleMutation.mutate({
              id: params.row.id,
              field: "connectToAllStar",
              value: !params.row.connectToAllStar, // Toggle the current value
            })
          }
        />
      ),
    },
    {
      field: "startAt",
      headerName: "Start At",
      // width: 180
      flex: 1,
    },
    {
      field: "endAt",
      headerName: "End At",
      // width: 180
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      // width: 100,
      flex: 1,
      renderCell: (row: any) => (
        <Box>
          <IconButton>
            <Edit
              onClick={() => {
                handleEditIncident(row.row);
              }}
            />
          </IconButton>

          <IconButton onClick={() => deleteIncident(row.row.id)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MainLayout>
        <Box sx={{ width: "100%" }}>
          <Box display="flex" justifyContent="space-between" mt={2} mb={2}>
            <Typography variant="h6">Incident Management</Typography>
            <Button
              variant="contained"
              onClick={() => {
                setEditIncidentId(null);
                setOpen(true);
                setAddIncident(true);
                resetForm();
              }}
            >
              Add Incident
            </Button>
          </Box>
          <DataGrid
            rows={incidents}
            columns={columns}
            getRowId={(row) => row.id}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableRowSelectionOnClick
            loading={isLoading}
          />
          <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
            <DialogTitle>
              {editIncidentId ? "Update Incident" : "Add Incident"}
            </DialogTitle>
            <DialogContent>
              <Grid2 container spacing={2} mt={2}>
                <Grid2 size={{ md: 12 }}>
                  <TextField
                    label="Name"
                    fullWidth
                    margin="dense"
                    value={formValues.name}
                    onChange={(e) =>
                      setFormValues({ ...formValues, name: e.target.value })
                    }
                  />
                </Grid2>
                <Grid2 size={{ md: 12 }}>
                  <TextField
                    label="Incident Message"
                    fullWidth
                    margin="dense"
                    value={formValues.alertMessage}
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        alertMessage: e.target.value,
                      })
                    }
                  />
                </Grid2>
                <Grid2 size={{ md: 12 }}>
                  <TextField
                    label="Description"
                    fullWidth
                    margin="dense"
                    value={formValues.description}
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        description: e.target.value,
                      })
                    }
                  />
                </Grid2>

                <Typography variant="h6">Options</Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formValues.refund}
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              refund: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Refund"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formValues.credit}
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              credit: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Credit"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formValues.moveFlight}
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              moveFlight: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Move Flight"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formValues.connectToAllStar}
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              connectToAllStar: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Connect to Allstar"
                    />
                  </Grid>
                </Grid>

                <Grid2 size={{ md: 12 }}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="startAt">Start At</FormLabel>
                    <DateTimePicker
                      label="Start At"
                      value={
                        formValues.startAt ? dayjs(formValues.startAt) : null
                      }
                      onChange={(date) =>
                        setFormValues({
                          ...formValues,
                          startAt: date ? date.toISOString() : "",
                        })
                      }
                    />
                  </FormControl>
                </Grid2>
                <FormControl fullWidth>
                  <FormLabel htmlFor="endAt">End At</FormLabel>
                  <DateTimePicker
                    label="End At"
                    value={formValues.endAt ? dayjs(formValues.endAt) : null}
                    onChange={(date) =>
                      setFormValues({
                        ...formValues,
                        endAt: date ? date.toISOString() : "",
                      })
                    }
                  />
                </FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formValues.isActive}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          isActive: e.target.checked,
                        })
                      }
                    />
                  }
                  label="Active"
                />
              </Grid2>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)}>Cancel</Button>
              <Button
                variant="contained"
                onClick={() =>
                  editIncidentId
                    ? updateIncidentMutation.mutate({
                        id: editIncidentId,
                        updatedValues: formValues,
                      })
                    : createIncident()
                }
              >
                {editIncidentId ? "Update" : "Add"}
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </MainLayout>
    </LocalizationProvider>
  );
}
